import PropTypes from "prop-types";
import React, {useRef, useState} from "react";
import {useDrag} from "react-dnd";

import styles from './DarggableItem.module.scss';

export const ItemTypes = {
    ITEM: 'item',
};

const DraggableItem = ({children, report}) => {

    const [{isDragging}, drag] = useDrag({
        type: ItemTypes.ITEM,
        item: {report},
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging(),
            };
        },
    });

    return(
        <div className={styles.property} ref={drag}>
            {children}
        </div>
    )
}
DraggableItem.propTypes = {
    children: PropTypes.node,
    report: PropTypes.object
}
export default DraggableItem;