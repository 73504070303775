import { useMediaQuery } from 'react-responsive'

export const useDesktop = () => useMediaQuery({ minWidth: 1440 })

export const useDesktopMini = () => useMediaQuery({ maxWidth: 1439, minWidth: 1201})

export const useLaptop = () => useMediaQuery({ maxWidth: 1200, minWidth: 993 })

export const useLaptopMini = () => useMediaQuery({ maxWidth: 992, minWidth: 769 })


export const useMobileAdditional = () => useMediaQuery({ maxWidth: 777 })
export const useMobile = () => useMediaQuery({ maxWidth: 768, minWidth: 577 })

export const useMobileMini = () => useMediaQuery({ maxWidth: 576 })

export const useMobileExtraMini = () => useMediaQuery({ maxWidth: 360 })
export const useLandscape = () => useMediaQuery({ query: '(orientation: landscape)' })
