import { GoogleOAuthProvider } from '@react-oauth/google';
import { Analytics } from '@vercel/analytics/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import store from './store';
// eslint-disable-next-line import/order
import App from './App';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-circular-progressbar/dist/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
// const auth = "<GOOGLE_CLIENT_ID>.apps.googleusercontent.com"
const auth = process.env.REACT_APP_GOOGLE_AUTH_KEY;

root.render(
    <GoogleOAuthProvider clientId={auth}>
        <Provider store={store}>
            <App />
            <Analytics />
        </Provider>
    </GoogleOAuthProvider>
);
