import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import styles from './Summary.module.scss';
import { Additional, Details, KeyPoints, Table } from './SummaryLayout';

import details from '@/assets/survey/fancyRoom.png';
import fix from '@/assets/survey/fix.png';
import house from '@/assets/survey/oasisHouse1.svg';
import building from '@/assets/survey/redhouse.png';
import { Img, Row } from '@/components/Layout';

export const Summary = ({ data }) => {
    return (
        <div className={data ? styles.bg_1 + ' py-5 px-3' : ''} style={data ? {} : { display: 'none' }}>
            <Row style={{ justifyContent: 'start', alignItems: 'end' }}>
                <Img src={house} style={{ height: '9rem' }} />
                <h2 className={'mb-4 ' + styles.accent_1} style={{ textAlign: 'left' }}><strong>My Household</strong></h2>
            </Row>
            <table className={'table ' + styles.accent_1} >
                <thead>
                    <tr>
                        <th scope='col' className='border border-light' style={{ color: 'white' }}>Demographics</th>
                        <th scope='col' className='border border-light' style={{ color: 'white' }}>Details</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.Table?.map((datum, key) => (
                        <tr key={key}>
                            <td className='border border-light' style={{ color: 'white' }}>{datum.Demographics}</td>
                            <td className='border border-light' style={{ color: 'white' }}>{datum.Details}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <h3 className={'mb-2 ' + styles.accent_1}>Summary:</h3>
            <p>{data?.Additional.join(" ")}</p>
        </div>
    );
};

Summary.propTypes = {
    data: PropTypes.any
}

export const Location = ({ data }) => {
    return (
        <div className={data ? styles.bg_2 + ' py-5 px-3' : ''} style={data ? {} : { display: 'none' }}>
            <Row style={{ justifyContent: 'start', alignItems: 'end' }}>
                <Img src={building} style={{ height: '10rem' }} />
                <h2 className={'mb-4'} style={{ textAlign: 'left' }}><strong>Location and Convenience</strong></h2>
            </Row>
            <table className={'table ' + styles.accent_2}>
                <thead>
                    <tr>
                        <th scope='col' className='border border-light' style={{ color: 'white' }}>Key Points</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.Table?.map((datum, key) => (
                        <tr key={key}>
                            <td className='border border-light' style={{ color: 'white' }}>{datum}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Additional additional={data?.Additional} />
        </div>
    );
};

Location.propTypes = {
    data: PropTypes.any
}

export const Priorities = ({ data }) => {
    useEffect(() => { console.log(data) }, [])
    return (
        <div className={data ? styles.bg_3 + ' py-5 px-3' : ''} style={data ? {} : { display: 'none' }}>
            <Row style={{ justifyContent: 'start', alignItems: 'end' }}>
                <Img src={details} style={{ height: '10rem' }} />
                <h2 className={'mb-4 ' + styles.accent_3} style={{ textAlign: 'left' }}><strong>Property Priorities</strong></h2>
            </Row>
            <table className={'table ' + styles.accent_3}>
                <thead>
                    <tr>
                        <th scope='col' className='border border-light' style={{ color: 'white' }}>Feature/Room</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.Table?.map((datum, key) => (
                        <tr key={key}>
                            <td className='border border-light' style={{ color: 'white' }}>{datum}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Additional additional={data?.Additional} />
        </div>
    );
};

Priorities.propTypes = {
    data: PropTypes.any
}

export const RiskTolerance = ({ data }) => {
    return (
        <div className={data ? styles.bg_4 + ' pt-5 pb-5 px-3' : ''} style={data ? {} : { display: 'none' }}>
            <Row style={{ justifyContent: 'start', alignItems: 'end' }}>
                <Img src={fix} style={{ height: '10rem' }} />
                <h2 className={'mb-3'} style={{ textAlign: 'left' }}><strong>Risk Tolerance</strong></h2>
            </Row>
            <p><strong>Will Accept: </strong>{data?.WillAccept}</p>
            <p><strong>Wants to Avoid: </strong>{data?.WantsToAvoid}</p>
            <p><strong>Openness to Tradeoffs: </strong>{data?.OpennessToTradeoffs}</p>
            <p><strong>Preferences and Lifestyle Considerations: </strong>{data?.PreferencesAndLifestyleConsiderations}</p>
        </div>
    );
};

RiskTolerance.propTypes = {
    data: PropTypes.any
}