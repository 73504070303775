import {configureStore} from '@reduxjs/toolkit';

import {userReducer} from './user/slice';

import {reportsReducer} from "@/store/reports/reportsSlice";
import {roomsReducer} from "@/store/rooms/roomsSlice";
import {stripeReducer} from "@/store/stripe/stripeSlice";

const store = configureStore({
    reducer: {
        user: userReducer,
        reports: reportsReducer,
        rooms: roomsReducer,
        stripe: stripeReducer,
    },
});

export default store;
export const {dispatch} = store;
