import PropTypes from "prop-types";
import React from "react";
import { useDrop } from "react-dnd";

import styles from './DropZone.module.scss';

import { KanbanState } from "@/api/report";
import { dispatch } from "@/store";
import { moveCardOnKanban } from "@/store/reports/reportsSlice";
import { ItemTypes } from "@/views/KanbanScreen/MainScreen/draggableItem/DraggableItem";



const DropZone = ({ children, columnStatus }) => {

    const [{ isOver }, drop] = useDrop(() => ({
        accept: ItemTypes.ITEM,
        drop: (item, monitor) => {
            let checked = (item.report.state === KanbanState.CONSIDERING && [KanbanState.VIEWED].includes(columnStatus)) ||
                (item.report.state === KanbanState.VIEWED && [KanbanState.CONSIDERING, KanbanState.AWAITING_DETAILS].includes(columnStatus)) ||
                (item.report.state === KanbanState.AWAITING_DETAILS && [KanbanState.VIEWED, KanbanState.SHORTLISTED].includes(columnStatus)) ||
                (item.report.state === KanbanState.SHORTLISTED && [KanbanState.AWAITING_DETAILS].includes(columnStatus));

            const direction = checked ? ((item.report.state === KanbanState.CONSIDERING && columnStatus === KanbanState.VIEWED) ||
                (item.report.state === KanbanState.VIEWED && columnStatus === KanbanState.AWAITING_DETAILS) ||
                (item.report.state === KanbanState.AWAITING_DETAILS && columnStatus === KanbanState.SHORTLISTED) ? 'right' : 'left') : null;

            checked && direction && dispatch(moveCardOnKanban({ direction, reportId: item.report.id }))
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),

    }), []);

    return (
        <div className={styles.properties_wrapper} ref={drop}>
            {children}
        </div>
    )
}

DropZone.propTypes = {
    children: PropTypes.node,
    columnStatus: PropTypes.string
}

export default DropZone;