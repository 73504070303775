import { track } from '@vercel/analytics/react';
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";

import styles from './Noise.module.scss';
import { Row, Button, Img, Col, Modal } from '../Layout/index';

import { UserAPI } from '@/api/user';
import home from '@/assets/survey/fancyHome.svg';

export const CurbAppeal = ({ isOpen, onClose = () => { }, onNext = () => { }, preference }) => {
    useEffect(() => {
        if (preference[questions.pref]) {
            setQuestions({ ...questions, response: preference[questions.pref] })
        }
    }, [preference])

    const [questions, setQuestions] = useState({
        title: "Curb Appeal",
        pref: "Curb Appeal",
        question: "When having your new home, how do you want your home to be viewed by the public?",
        choices: [
            {
                value: '8',
                name: <div>The community is in awe by your new house</div>,
            },
            {
                value: '6',
                name: <div>A passerby couldn&apos;t help but take a third look</div>,
            },
            {
                value: '4',
                name: <div>Two monthly viewers of the house</div>,
            },
            {
                value: '2',
                name: <div>It&apos;s just a regular home, nothing crazy</div>,
            },
        ],
        response: '',
    });

    const handleResponse = (e) => {
        e.preventDefault();
        setQuestions({ ...questions, response: e.target.value });
    }

    const goNext = () => {
        if (questions.response !== '') {
            const body = {
                "score": parseInt(questions.response),
                "preference": "Curb Appeal"
            }
            UserAPI.setUserPreferences(body);
            track("Completed Intake Quiz");
            onNext();
        }
    }

    const superscript = <>
        <p style={{ display: 'inline', fontWeight: '600', fontSize: '1.5rem', fontFamily: 'Poppins' }}>8</p>
        <p style={{ display: 'inline', fontFamily: 'Poppins' }}>/8</p>
    </>

    return (
        <Modal isOpen={isOpen} onClose={onClose} title={questions.title} text={superscript}>
            <Col className={styles.contents_wrapper}>
                <p className={styles.description}>{questions.question}</p>
                <Row className={styles.main_content}>
                    <Img className={styles.image} src={home} />
                    <Col className={styles.questions_wrapper}>
                        {questions.choices.map((choice) => (
                            <Row key={choice.value} className={styles.mcq}>
                                <Button
                                    className={questions.response === choice.value ? styles.choose : styles.chose}
                                    style={{ backgroundColor: 'var(--survey-curbAppel)' }}
                                    value={choice.value}
                                    onClick={handleResponse}
                                />
                                <p>{choice.name}</p>
                            </Row>
                        ))}
                    </Col>
                </Row>
                <Button className={styles.continueBtn} onClick={goNext}>Save & Continue</Button>
            </Col>
        </Modal>
    )
}
CurbAppeal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    preference: PropTypes.any,
}