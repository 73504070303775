import PropTypes from "prop-types";
import React from "react";
import {Link} from "react-router-dom";

import styles from './issueMenu.module.scss';

import {Col} from "@/components/Layout";
import {useDesktop, useDesktopMini} from "@/hooks/useAdaptives";

export const IssueMenu = ({issueType, tabClick, showBig, urgentIssues, moderateIssues, lowIssues}) => {
    const isDesktop = useDesktop();
    const isDesktopMini = useDesktopMini();

    const menuItems = [
        {
            id: 1,
            itemIssueType: 'urgent',
            title: 'Urgent Priority',
            description: 'Problems that pose an immediate threat to the safety & integrity of the home.'
        },
        {
            id: 2,
            itemIssueType: 'moderate',
            title: 'Moderate Priority',
            description: 'Significant repair or maintenance issues that may affect the functionality of the home.'
        },
        {
            id: 3,
            itemIssueType: 'low',
            title: 'Low Priority',
            description: 'Minor cosmetic issues that do not impact the safety or the functionality of the home.'
        },
    ]


    return (
        // <div className={styles.levelsMenu + " " + (showBig ? styles.levelsMenuWidth : '')}>
        isDesktop || isDesktopMini
            ? <div className={`${styles.levelsMenu} flex-shrink-0`}>
                {
                    menuItems.map(i => {
                        return <Col onClick={() => (tabClick(i.itemIssueType))}
                                    key={i.id}
                                    className={`${issueType === i.itemIssueType ? `${styles.current} ${styles[i.itemIssueType]}` : ''} ${styles.item} w-100 position-relative flex-shrink-0 cursor-pointer ms-n1`}>
                                       <span className={`${styles.title} d-flex gap-2`}>
                                           <span
                                               className={`${styles.counter} d-flex justify-content-center align-items-center rounded-3 text-white`}>
                                               {i.itemIssueType === 'urgent' ? urgentIssues.length
                                                   : i.itemIssueType === 'moderate' ? moderateIssues.length
                                                       : i.itemIssueType === 'low' ? lowIssues.length : ''}
                                           </span>
                                           {i.title}
                                       </span>
                            <span className={styles.description}>{i.description}</span>
                        </Col>
                    })
                }
            </div>
            :
            <div className={`${styles.tabs} w-100 d-flex rounded-pill justify-content-between mb-5`}>
                {Object.values(menuItems).map((item, index) => (
                    <div key={index}
                         onClick={() => (tabClick(item.itemIssueType))}
                         className={styles.tab + ' d-flex position-relative justify-content-center align-items-center gap-2 py-3 px-4 rounded-pill ' + (issueType === item.itemIssueType ? `${styles.current} ${styles[item.itemIssueType]}` : '')}>
                        {
                            item.itemIssueType.charAt(0).toUpperCase() + item.itemIssueType.slice(1)
                        }
                    </div>
                ))}
            </div>
    )
}

IssueMenu.propTypes = {
    issueType: PropTypes.string,
    tabClick: PropTypes.func,
    showBig: PropTypes.bool,
    urgentIssues: PropTypes.array,
    moderateIssues: PropTypes.array,
    lowIssues: PropTypes.array
};