import { track } from "@vercel/analytics/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import styles from './index.module.scss';

import { UserAPI } from "@/api/user";
import { Col, Layout, Button, Input, MobileLayout, Layout as MainLayout, Row } from "@/components/Layout";
import MobileTopBar from "@/components/mobileTopBar/mobileTopBar";
import { useDesktop, useDesktopMini } from "@/hooks/useAdaptives";

export const Password = () => {
    const navigate = useNavigate();
    const isDesktop = useDesktop();
    const isDesktopMini = useDesktopMini();

    const [password, setPassword] = useState({
        curr_password: "",
        new_password: "",
        repeat: "",
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password.new_password === password.curr_password) {
            toast.warn("New password is the same as the old");
            track("Password Reset", { status: "Failed", message: "Same password" });
        } else if (password.new_password !== password.repeat) {
            toast.warn("New password does not match");
            track("Password Reset", { status: "Failed", message: "Unmatching passwords" });
        } else {
            await UserAPI.changePassword(password.new_password, password.repeat);
            toast.success("Password changed");
            setPassword({ ...password, curr_password: '', new_password: '', repeat: ''});
            track("Password Reset", { status: "Success", message: "Success" });
        }
    }

    const handleChange = (e, event) => {
        setPassword({ ...password, [event.target.name]: event.target.value });
    }

    const Layout = !isDesktop && !isDesktopMini ? MobileLayout : MainLayout;

    return (
        <Layout mainAddClass={!isDesktop && !isDesktopMini ? styles.mobile_main : ''}>
            {!isDesktop && !isDesktopMini ? <MobileTopBar title={''} style={{ background: 'inherit' }} /> : null}
            <Col className={'w-100 justify-content-center align-items-center px-2 px-md-5 py-2'}>
                <div className={`${styles.card} card mx-auto w-100 align-items-center`}>
                    <div className="card-body w-75">
                        <h1>Password Reset</h1>
                        <form className={styles.data_card} onSubmit={handleSubmit}>
                            <div className={styles.profile_data}>
                                <label>Current Password</label>
                                <Input className={styles.input} type="password" value={password.curr_password} name="curr_password" onChange={handleChange} required />
                            </div>
                            <div className={styles.profile_data}>
                                <label>New Password</label>
                                <Input className={styles.input} type="password" value={password.new_password} name="new_password" onChange={handleChange} required />
                            </div>
                            <div className={styles.profile_data}>
                                <label>New Password &#40;Again&#41;</label>
                                <Input className={styles.input} type="password" value={password.repeat} name="repeat" onChange={handleChange} required />
                            </div>
                            <Button type="submit" className={'mx-auto my-0'} style={{ fontSize: '1rem' }}>Save Password</Button>
                        </form>
                    </div>
                </div>
                <Row style={!isDesktop && !isDesktopMini ? {} : { display: 'none' }}>
                    <Button className={'mt-5 me-3'} onClick={() => { navigate('/profile') }}>View Profile</Button>
                    <Button className={'mt-5'} style={{ backgroundColor: '#E22030', borderColor: '#E22030' }} onClick={() => { navigate('/logout') }}>Sign Out</Button>
                </Row>
            </Col>
        </Layout>
    )
}