import PropTypes from "prop-types";
import React from "react";
import {useNavigate} from "react-router-dom";

import styles from './mobileTopBar.module.scss';

import bell from "@/assets/rooms/bell.png";
import icon_back from "@/assets/rooms/icon_back.png";
import {Icon} from "@/components/Layout";

const MobileTopBar = ({title, link, goBack, ...props}) => {
    const navigate = useNavigate();

    const goBackAction = () => {
        if(goBack) {
            goBack();
            return
        }
      link ? navigate(link) : navigate(-1);
    }

    return(
        <div className={styles.room_menu} {...props}>
            <div className={styles.notification_button} onClick={goBackAction}>
                <Icon src={icon_back} style={{width: '10px', minWidth: '10px', height: '17px'}}
                      className={`flex-shrink-0`}/>
            </div>
            <div className={styles.title}>{title}</div>
            <div className={styles.notification_button}>
                <Icon src={bell} style={{width: '17px', minWidth: '17px', height: '18px'}}
                      className={`flex-shrink-0`}/>
                {/*<div className={styles.red_point}></div>*/}
            </div>
        </div>
    )
}

MobileTopBar.propTypes = {
    title: PropTypes.string,
    link: PropTypes.string,
    goBack: PropTypes.func
}
export default MobileTopBar;