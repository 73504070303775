import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ReportAPI } from "@/api/report";
import { formatString } from "@/utils";


const initialState = {
    rooms: [],
    roomsImages: [],
    chosenRoom: null,
    photos: [],
    // tabForMovingReports: null,
};

export const getAllRooms = createAsyncThunk(
    'reports/getRooms',
    async ({ reportId }, { dispatch }) => {
        const res = await ReportAPI.getRooms(reportId);
        let resObj = res.data.message.images;
        let arr = [];
        for (let key in resObj) {
            if (resObj.hasOwnProperty(key)) {
                arr.push(resObj[key])
            }
        }

        let rooms = arr.map(item => ({
            ...item,
            room_type: formatString(item.room_type),
            features: item.features.map(f => ({ ...f, feature: formatString(f.feature) }))
        }));

        dispatch(getRooms(rooms))
    }
)

export const getRoomsPhotos = createAsyncThunk(
    'reports/getRoomsImages',
    async ({ reportId }, { dispatch }) => {
        const res = await ReportAPI.getRoomsPictures(reportId);
        let resArr = res.data.images;

        let roomsImages = resArr.map(item => ({
            ...item,
            room_type_str: formatString(item.room_type_str)
        }))

        dispatch(getRoomsImages(roomsImages))
    }
)

export const roomsSlice = createSlice(({
    name: 'rooms',
    initialState,
    reducers: {
        chooseRoom: (state, action) => {
            state.chosenRoom = action.payload
        },
        savePhoto: (state, action) => {
            state.photos = [...action.payload, ...state.photos]
        },
        getRooms: (state, action) => {
            state.rooms = action.payload
        },
        getRoomsImages: (state, action) => {
            state.roomsImages = action.payload
        },
    }
}))

export const { chooseRoom, savePhoto, getRooms, getRoomsImages } = roomsSlice.actions;

export const roomsReducer = roomsSlice.reducer;