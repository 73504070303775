import { UploadSimple, X } from "@phosphor-icons/react";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import styles from './MobileScreenRooms.module.scss';

import { ReportAPI } from "@/api/report";
import plusCircle from "@/assets/icons/plusCircle.svg";
import xThin from "@/assets/icons/xThin.svg";
import basement from '@/assets/rooms/basement.png';
import kitchen from '@/assets/rooms/kitchen.png';
import master_bedroom from '@/assets/rooms/master_bedroom.png';
import rear_deck from '@/assets/rooms/rear_Deck.png';
import roof from '@/assets/rooms/roof.png';
import CustomMuiDialogWindow from "@/components/customMuiDialogWindow/customMuiDialogWindow";
import { Col, Icon, MobileLayout, Row } from "@/components/Layout";
import MobileTopBar from "@/components/mobileTopBar/mobileTopBar";
import { useDesktop, useDesktopMini } from "@/hooks/useAdaptives";
import { dispatch } from "@/store";
import { chooseRoom, savePhoto } from "@/store/rooms/roomsSlice";
import WebcamComponent from "@/views/PropertyInsights/rooms/mobileScreenRooms/webcamComponent/webcamComponent";

const MobileScreenRooms = ({ report }) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const isDesktop = useDesktop();
    const isDesktopMini = useDesktopMini();
    const navigate = useNavigate();
    const { chosenReport } = useSelector(state => state.reports);
    const { chosenRoom, photos, rooms } = useSelector(state => state.rooms);
    const [homeRooms, setHomeRooms] = useState([]);
    const propertyId = chosenReport?.id;
    const [loadingRoom, setLoadingRoom] = useState(true);

    const roomMenuImages = [
        rear_deck,
        basement,
        kitchen,
        roof,
        master_bedroom,
    ]

    function formatRoomType(roomType) {
        if (roomType === null || roomType === undefined) {
            return ''
        }

        return roomType
            .split('_') // Split by underscores
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
            .join(' '); // Join them back with spaces
    }

    useEffect(() => {
        const getRooms = async () => {
            setLoadingRoom(true);
            const items = await ReportAPI.getRooms(propertyId);
            setLoadingRoom(false);
            let id = 1
            const menuItems = []
            const feats = {}

            for (const key in items.data.message.images) {
                if (items.data.message.images.hasOwnProperty(key)) {
                    menuItems.push({
                        id: id,
                        type: key,
                        title: formatRoomType(items.data.message.images[key].room_type),
                        description: '',
                        image: roomMenuImages[id - 1]
                    });
                    feats[key] = items.data.message.images[key].features
                }
                id += 1;
            }
            setHomeRooms(menuItems);
        }

        getRooms()
    }, [])

    const onMenuItemClick = (menuItem) => {
        const chosenRoom = rooms.find((r) => {
            return r.room_type === menuItem.title
        })
        dispatch(chooseRoom(chosenRoom));
        navigate(menuItem.type);
    }

    //custom styles for modal window witch shows 2 buttons for work with camera and images
    const dialogStyles = {
        backgroundColor: 'inherit',
        width: '100%',
        maxWidth: '100%',
        margin: '7px',
        //border: 'none',
        boxShadow: "none",
        alignSelf: 'flex-end',
    }

    const dialogContentStyles = {
        textAlign: 'center',
        padding: '0'
    }
    //open modal window
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    //for camera
    const webcamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);

    const [webcamMWIsOpen, setWebcamMWIsOpen] = useState(false);

    const webcamDialogStyles = {
        backgroundColor: 'inherit',
        width: '100%',
        maxWidth: '800px',
        margin: '7px',
        borderRadius: '25px',
    }

    const webcamDialogContentStyles = {
        textAlign: 'center',
        padding: '0'
    }

    const handleOpenWebcam = () => {
        setOpen(false);
        setWebcamMWIsOpen(true);
    };

    const handleCloseWebcam = () => {
        setWebcamMWIsOpen(false);
        setImgSrc(null);
    };

    //for images uploader
    const [imgUploaderMWIsOpen, setImgUploaderMWIsOpen] = useState(false);
    const [files, setFiles] = useState([]);

    const handleChange = useCallback((fileList) => {
        setFiles([...fileList, ...files]);
    }, [files])

    const uploaderDialogStyles = {
        backgroundColor: 'inherit',
        width: '100%',
        maxWidth: '800px',
        margin: '7px',
        borderRadius: '25px',
    }

    const uploaderDialogContentStyles = {
        textAlign: 'center',
        padding: '0'
    }
    const handleOpenFileUploader = () => {
        setOpen(false);
        setImgUploaderMWIsOpen(true);
    }

    const handleCloseFileUploader = () => {
        setImgUploaderMWIsOpen(false);
        setFiles([]);
    };

    const removeLoadedImage = useCallback((fileName) => {
        let newF = files.filter(f => f.name !== fileName);
        setFiles(newF)
    }, [files])

    const uploadPhotos = useCallback(() => {
        const photos = files?.map(f => URL.createObjectURL(f));
        if (photos?.length) {
            dispatch(savePhoto(photos));
            toast.success("Images were loaded successfully!");
            setFiles([]);
        }
    }, [files])

    return (
        isDesktop || isDesktopMini || !chosenReport ? <Navigate to={'/'} />
            : <MobileLayout mainAddClass={!isDesktop && !isDesktopMini ? styles.mobile_main : ''}>

                <CustomMuiDialogWindow open={webcamMWIsOpen}
                    onClose={handleCloseWebcam}
                    dialogStyles={webcamDialogStyles}
                    dialogContentStyles={webcamDialogContentStyles}>
                    <WebcamComponent imgSrc={imgSrc} setImgSrc={setImgSrc} webcamRef={webcamRef}
                        handleCloseWebcam={handleCloseWebcam} />
                </CustomMuiDialogWindow>

                <CustomMuiDialogWindow open={imgUploaderMWIsOpen}
                    onClose={handleCloseFileUploader}
                    dialogStyles={uploaderDialogStyles}
                    dialogContentStyles={uploaderDialogContentStyles}>
                    <div className={styles.file_uploader_wrapper}>
                        <FileUploader
                            multiple={true}
                            handleChange={handleChange}
                            name="images"
                            types={["JPEG", "JPG", "PNG", "GIF", "BMP", "TIFF", "SVG", "WEBP", "ICO", "HEIC"]}
                            classes={`${styles.fileUploader} d-flex flex-column align-items-center cursor-pointer`}
                        >
                            <Icon src={plusCircle} size={24} />
                            <h3>Drop your files here</h3>
                            <p>
                                <u>Browse</u> file from your device
                            </p>
                        </FileUploader>

                        {
                            files?.length > 0 && (
                                <div className={styles.images_wrapper}>
                                    {Array.from(files).map((file, index) => (
                                        <div key={index} className={styles.image_item}>
                                            <div className={styles.image_item_header}>
                                                <p>file_{index}</p>
                                                <Icon src={xThin} size={24} onClick={() => removeLoadedImage(file.name)} />
                                            </div>
                                            <img
                                                src={URL.createObjectURL(file)}
                                                alt={file.name}
                                                style={{ width: '100%', maxHeight: '100px', objectFit: 'contain' }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )
                        }

                        <div className={styles.controls_wrapper}>
                            <div
                                className={`d-flex align-items-center justify-content-center rounded-circle cursor-pointer 
                            ${styles.btn} ${!files?.length ? styles.disabled : ''}`}
                                onClick={uploadPhotos}>
                                <UploadSimple color={'#ffffff'} />
                            </div>
                            <div
                                className={`d-flex align-items-center justify-content-center rounded-circle cursor-pointer 
                            ${styles.btn}`}
                                onClick={handleCloseFileUploader}>
                                <X color={'#ffffff'} />
                            </div>
                        </div>



                    </div>
                </CustomMuiDialogWindow>


                <CustomMuiDialogWindow open={open}
                    onClose={handleClose}
                    dialogStyles={dialogStyles}
                    dialogContentStyles={dialogContentStyles}>
                    <div className={styles.photos_work_menu}>
                        <div onClick={handleOpenWebcam}>Camera</div>
                        <div onClick={handleOpenFileUploader}>Photo Library</div>
                    </div>
                </CustomMuiDialogWindow>

                <MobileTopBar title={chosenReport?.name || ''} />

                <Col className={'w-100 justify-content-start'}>
                    {/* <Contents>
                        <div className={styles.tour} onClick={handleClickOpen}>
                            <img src={house} alt="" />
                            <div className={styles.tour_info_wrapper}>
                                <div className={styles.tour_info_wrapper_title}>Snap & Tour</div>
                                <div className={styles.tour_info_wrapper_label}>Open House Mode</div>
                            </div>

                        </div>
                    </Contents> */}
                    {
                        loadingRoom ?
                            (
                                <Row style={{ justifyContent: 'center', alignSelf: 'center' }}>
                                    <h1>
                                        Loading Rooms...
                                    </h1>
                                    <div className="spinner-border text-dark" role="status">
                                    </div>
                                </Row>
                            )
                            :
                            (
                                <div className={styles.menu}>
                                    {homeRooms.map(item => {
                                        return (
                                            <div onClick={() => onMenuItemClick(item)}
                                                className={styles.menu_item} key={item.id}>
                                                <div className={styles.menu_item_avatar}>
                                                    <img src={item.image} alt="" />
                                                </div>
                                                <div className={styles.menu_item_info}>
                                                    <div className={styles.title}>{item.title}</div>
                                                    {/*<div className={styles.description}>{item.description}</div>*/}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                    }
                </Col>
            </MobileLayout>
    )
}

MobileScreenRooms.propTypes = {
    report: PropTypes.object,
};
export default MobileScreenRooms;