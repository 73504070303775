import PropTypes from 'prop-types';
import { useEffect, useState } from "react";

import styles from './Noise.module.scss';
import { Row, Button, Img, Col, Modal } from '../Layout/index';

import { UserAPI } from '@/api/user';
import firePit from '@/assets/survey/firePit.svg';

export const Amenities = ({
    isOpen, onClose = () => {
    }, onNext = () => {
    }, preference
}) => {
    useEffect(() => {
        if (preference[questions.pref]) {
            setQuestions({ ...questions, response: preference[questions.pref] })
        }
    }, [preference])

    const [questions, setQuestions] = useState({
        title: "Amenities",
        pref: "Amenities",
        question: "In your ideal home, what kind of amenities do you expect to have?",
        choices: [
            {
                value: '8',
                name: <div>Like a mansion! Has everything!</div>,
            },
            {
                value: '6',
                name: <div>A pool or maybe a home gym</div>,
            },
            {
                value: '4',
                name: <div>A small garden or a patio</div>,
            },
            {
                value: '2',
                name: <div>Just the basics: kitchen and laundry room</div>,
            },
        ],
        response: '',
    });

    const handleResponse = (e) => {
        e.preventDefault();
        setQuestions({ ...questions, response: e.target.value });
    }

    const goNext = () => {
        if (questions.response !== '') {
            const body = {
                "score": parseInt(questions.response),
                "preference": "Amenities"
            }
            UserAPI.setUserPreferences(body);
            onNext();
        }
    }

    const superscript = <>
        <p style={{ display: 'inline', fontWeight: '600', fontSize: '1.5rem', fontFamily: 'Poppins' }}>4</p>
        <p style={{ display: 'inline', fontFamily: 'Poppins' }}>/8</p>
    </>

    return (
        <Modal isOpen={isOpen} onClose={onClose} title={questions.title} text={superscript}>
            <Col className={styles.contents_wrapper}>
                <p className={styles.description}>{questions.question}</p>
                <Row className={styles.main_content}>
                    <Img className={styles.image} src={firePit} />
                    <Col className={styles.questions_wrapper}>
                        {questions.choices.map((choice) => (
                            <Row key={choice.value} className={styles.mcq}>
                                <Button
                                    className={questions.response === choice.value ? styles.choose : styles.chose}
                                    style={{ backgroundColor: 'var(--survey-amenities)' }}
                                    value={choice.value}
                                    onClick={handleResponse}
                                />
                                <p>{choice.name}</p>
                            </Row>
                        ))}
                    </Col>
                </Row>
                <Button className={styles.continueBtn} onClick={goNext}>Save & Continue</Button>
            </Col>
        </Modal>
    )
}

Amenities.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    preference: PropTypes.any,
}