import PropTypes from "prop-types";
import React, {useState} from "react";

import styles from './onboarding.module.scss';

import Complexity from "@/views/Onboarding/complexity";
import HomeBuying from "@/views/Onboarding/homeBuying";
import HomeSearch from "@/views/Onboarding/homeSearch";
import PerfectFit from "@/views/Onboarding/perfectFit";
import PropertyQuality from "@/views/Onboarding/propertyQuality";

const Onboarding = ({onClose = () => {}}) => {

    const [step, setStep] = useState({
        homeSearch: true,
        propertyQuality: false,
        homeBuying: false,
        complexity: false,
        perfectFit: false,
    });

    return(
        <>
            {step.homeSearch && <HomeSearch onClose={onClose} onNext={() => (setStep({ ...step, homeSearch: false, propertyQuality: true }))} />}
            {step.propertyQuality && <PropertyQuality onClose={onClose} onNext={() => (setStep({ ...step, propertyQuality: false, homeBuying: true }))} />}
            {step.homeBuying && <HomeBuying onClose={onClose} onNext={() => (setStep({ ...step, homeBuying: false, complexity: true }))} />}
            {step.complexity && <Complexity onClose={onClose} onNext={() => (setStep({ ...step, complexity: false, perfectFit: true }))} />}
            {step.perfectFit && <PerfectFit  onClose={onClose} onNext={onClose} />}
        </>
    )
}

Onboarding.propTypes = {
    onClose: PropTypes.func.isRequired,
}

export default Onboarding;