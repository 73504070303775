import {Camera, CameraRotate, FloppyDisk, Trash} from "@phosphor-icons/react";
import PropTypes from "prop-types";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import Webcam from "react-webcam";

import styles from './webcamComponent.module.scss';

import xThin from "@/assets/icons/xThin.svg";
import {Icon} from "@/components/Layout";
import {dispatch} from "@/store";
import {savePhoto} from "@/store/rooms/roomsSlice";


const WebcamComponent = ({imgSrc, setImgSrc, webcamRef, handleCloseWebcam}) => {
    const [facingMode, setFacingMode] = useState('user');

    const makePhoto = useCallback(() => {
        if (webcamRef?.current) {
            const imageSrc = webcamRef?.current?.getScreenshot();
            setImgSrc(imageSrc);
        }
    }, [webcamRef]);

    const saveScreenshot = useCallback(() => {
        imgSrc && dispatch(savePhoto([imgSrc]))
        toast.success("The photo was saved successfully!");
        setImgSrc(null);
    }, [imgSrc])

    const retakePhoto = () => {
        setImgSrc(null)
    }

    const handleSwitchCamera = () => {
        setFacingMode(prevMode => (prevMode === "user" ? "environment" : "user"));
    };

    return (
        <div
            className={`w-100 my-0 mx-auto position-relative d-flex flex-column justify-content-center align-items-center ${styles.webcam_component}`}>

            <Icon src={xThin} size={24} onClick={handleCloseWebcam}
                  className={`position-absolute cursor-pointer ${styles.close_btn}`}/>

            <div className={styles.webcam_wrapper}>
                {imgSrc ? (
                    <img src={imgSrc} alt="webcam"/>
                ) : (
                    <Webcam ref={webcamRef}
                            videoConstraints={{facingMode}}
                            onUserMediaError={(error) => toast.warn(`Сamera access error: ${error}`)}/>
                )}
            </div>

            <div className={'d-flex justify-content-center align-items-center gap-4 mt-5'}>
                {
                    imgSrc
                        ?
                        <>
                            <div
                                className={`d-flex align-items-center justify-content-center rounded-circle cursor-pointer ${styles.icon_wrapper}`}
                                onClick={retakePhoto}>
                                <Trash color={'#ffffff'}/>
                            </div>
                            <div
                                className={`d-flex align-items-center justify-content-center rounded-circle cursor-pointer ${styles.icon_wrapper}`}
                                onClick={saveScreenshot}>
                                <FloppyDisk color={'#ffffff'}/>
                            </div>
                        </>
                        :
                        <>
                            <div
                                className={`d-flex align-items-center justify-content-center rounded-circle cursor-pointer ${styles.icon_wrapper}`}
                                onClick={makePhoto}>
                                <Camera color={'#ffffff'}/>
                            </div>
                            <div
                                className={`d-flex align-items-center justify-content-center rounded-circle cursor-pointer ${styles.icon_wrapper}`}
                                onClick={handleSwitchCamera}>
                                <CameraRotate color={'#ffffff'}/>
                            </div>
                        </>
                }
            </div>

        </div>
    )
}

WebcamComponent.propTypes = {
    imgSrc: PropTypes.string,
    setImgSrc: PropTypes.func,
    webcamRef: PropTypes.shape({current: PropTypes.instanceOf(Element)}),
    handleCloseWebcam: PropTypes.func,
};

export default WebcamComponent;