import PropTypes from "prop-types";
import React, {useState} from "react";

import styles from './complexity.module.scss';

import uploader from '@/assets/onboarding/complexity/uploader 1.png';
import {Button} from "@/components/Layout";
import {ColorAccent} from "@/constants";
import {useWindowHeight} from "@/hooks/useWindowHeight";
import common_styles from "@/views/Onboarding/onboarding.module.scss";



const Complexity = ({
                        onClose = () => {
                        },
                        onNext = () => {
                        }
                    }) => {
    const windowHeight = useWindowHeight();

    return (
        <div className={styles.main_wrapper}>
            <div className={common_styles.content_wrapper}>
                <img src={uploader} alt="" className={styles.uploader}/>

                <div className={`${common_styles.info_card} ${windowHeight < 730 ? common_styles.small : ''}`}>
                    <div className={common_styles.title}>Make sense of the <br/> complexity</div>
                    <div className={common_styles.description}>Add your home inspection or property disclosures to learn more about problem areas.  </div>
                    <Button colorAccent={ColorAccent.black} className={'w-100 py-3 mb-3'} onClick={onNext}>
                        Next
                    </Button>
                    <div className={common_styles.skip} onClick={onClose}>Skip for now</div>
                </div>
            </div>
        </div>
    )
}

Complexity.propTypes = {
    onClose: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
}
export default Complexity;