import PropTypes from "prop-types";
import React, {useState} from "react";

import styles from './perfectFit.module.scss';

import uploader from "@/assets/onboarding/complexity/uploader 1.png";
import {Button} from "@/components/Layout";
import {ColorAccent} from "@/constants";
import {useWindowHeight} from "@/hooks/useWindowHeight";
import common_styles from "@/views/Onboarding/onboarding.module.scss";



const PerfectFit = ({
                        onClose = () => {
                        },
                        onNext = () => {
                        }
                    }) => {
    const windowHeight = useWindowHeight();

    return(
        <div className={styles.main_wrapper}>
            <div className={common_styles.content_wrapper}>

                <div className={`${common_styles.info_card} ${windowHeight < 730 ? common_styles.small : ''}`}>
                    <div className={common_styles.title}>Determine if a home is your <br/> &apos;Perfect Fit&lsquo;</div>
                    <div className={common_styles.description}>Reveal how each property measures up against the features that are most important to you.</div>
                    <Button colorAccent={ColorAccent.black} className={'w-100 py-3 mb-3'} onClick={onNext}>
                        Let&apos;s start
                    </Button>
                    <div className={common_styles.skip} onClick={onClose}>Skip for now</div>
                </div>
            </div>
        </div>
    )
}

PerfectFit.propTypes = {
    onClose: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
}
export default PerfectFit;