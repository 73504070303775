import { CheckFat } from '@phosphor-icons/react';
import { track } from '@vercel/analytics/react';
import React, { useState, useEffect } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { toast } from "react-toastify";

import styles from './index.module.scss';

import { ReportAPI } from '@/api/report';
import { UserAPI } from '@/api/user';
import blue_house from "@/assets/dashboard/bluehouse.png";
import addWhiteIcon from '@/assets/icons/addWhite.svg';
import { Demo } from '@/components/Demo';
import { HousesView } from '@/components/HousesView';
import { Button, Contents, Layout, Row, Icon, Input, Col, MobileLayout, Layout as MainLayout } from '@/components/Layout';
import { Survey } from '@/components/v1Survey';
import { WarnUpload } from '@/components/WarnUpload';
import { ColorAccent } from "@/constants";
import { useDesktop, useDesktopMini } from "@/hooks/useAdaptives";
import { dispatch } from "@/store";
import { removeHome } from "@/store/reports/reportsSlice";

const MAPS_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export const Home = () => {
    const [reports, setReports] = useState([]);
    // const [userPreferences, setUserPreferences] = useState(0);
    const [warn, setWarn] = useState(false);
    // const [demo, setDemo] = useState(false);
    const [chosenLocation, setChosenLocation] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const isDesktop = useDesktop();
    const isDesktopMini = useDesktopMini();

    const { ref, autocompleteRef } = usePlacesWidget({
        apiKey: MAPS_API_KEY,
        onPlaceSelected: (place) => {
            place?.formatted_address && setChosenLocation(place.formatted_address)
        },
        options: {
            types: ['address'],
            componentRestrictions: { country: 'us' },
        },
    });

    // useEffect(() => {
    //     ReportAPI.getReports().then(data => {
    //         setReports(data);
    //         if (data.length <= 1) {
    //             setDemo(true)
    //         }
    //     });
    // }, []);

    // useEffect(() => {
    //     UserAPI.getUserPreferences().then(data => {
    //         setUserPreferences(data.length);
    //     })
    // }, [])

    const createNewReport = e => {
        e.preventDefault();
        if (chosenLocation && !isLoading) {
            setIsLoading(true)
            ReportAPI.uploadNew(chosenLocation).then(data => {
                ref.current.value = '';
                setChosenLocation(null);
                toast.success("Added a new home!");
                setIsLoading(false)
                ReportAPI.getReports().then(data => {
                    setReports(data);
                    const has_reports = data.filter(report => report.has_report);
                    has_reports.length < 2 && setWarn(true)
                });
            }).catch(err => setIsLoading(false));
        }
    };

    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            createNewReport(event);
        }
    }

    //method for removing property card
    //need backend
    const deleteReport = (reportId) => {
        const newReports = reports.filter(r => r.id !== reportId);
        dispatch(removeHome({ reportId, setLocalState: () => setReports(newReports) }));
    }

    const Layout = !isDesktop && !isDesktopMini ? MobileLayout : MainLayout;

    return (
        <Layout className={styles.layout}
                mainAddClass={!isDesktop && !isDesktopMini ? styles.mobile_main : ''}>
            <Col className={`w-100 align-items-center flex-grow-1 ${styles.main_content}`} >
                <Contents>
                    {/*<Row style={{justifyContent: 'flex-end'}}>*/}
                    {/*    <Demo isOpen={demo} onClose={() => {*/}
                    {/*        setDemo(false)*/}
                    {/*    }} onNext={() => {*/}
                    {/*        setDemo(false)*/}
                    {/*    }}/>*/}
                    {/*    <Survey title={userPreferences < 8 ? <>Take our Buyer Preference Quiz</> : <>Quiz*/}
                    {/*        completed <CheckFat color={'green'} weight={'fill'} size={'1.5rem'}/></>}/>*/}
                    {/*</Row>*/}
                    <div className={styles.mainTitle}>
                        Explore Beyond the Front Door
                    </div>

                    <Row className={`flex-nowrap gap-4 w-100 py-3 px-3 px-md-5 mt-4 rounded-pill border bg-white ${styles.addressInputBar}`}>
                        <input
                            onKeyDown={handleKeyDown}
                            ref={ref}
                            placeholder={'Add addresses of homes you\'re seriously considering'}
                            className={`w-100 ${styles.input}`}
                            style={{ opacity: isLoading ? '.5' : '1' }}
                            disabled={isLoading}
                        />
                        <Button className={`${styles.add_btn} p-0 border-0`} colorAccent={ColorAccent.white} onClick={createNewReport}>
                            <Icon src={addWhiteIcon} />
                        </Button>
                    </Row>

                    <WarnUpload isOpen={warn} onClose={() => (setWarn(false))} onNext={() => (setWarn(false))} />
                </Contents>
                <HousesView reports={reports} deleteReport={deleteReport} />
            </Col>


            <Col className={`justify-content-center align-items-center ${styles.message_wrapper}`}>
                <div className={styles.title}>Coming Soon</div>
                <img src={blue_house} alt=""/>
                <div className={styles.message}>
                    We’re building a better way to buy a home... stay tuned!
                </div>
            </Col>
        </Layout>
    );
};
