import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

import styles from './index.module.scss'
import { Summary, Location, Priorities, RiskTolerance } from './Summary';

import { UserAPI } from '@/api/user';
import logo from '@/assets/headerInverseLogo.svg';
import Card from '@/components/Card';
import { Col, Img, Layout, Input, Button, MobileLayout, Layout as MainLayout, Contents, Row } from '@/components/Layout';
import MobileTopBar from "@/components/mobileTopBar/mobileTopBar";
import { Survey } from '@/components/v2Survey';
import { useDesktop, useDesktopMini } from "@/hooks/useAdaptives";


const DOCS_PRIVACY_URL = process.env.REACT_APP_DOCS_PRIVACY_URL;
const DOCS_TERMS_URL = process.env.REACT_APP_DOCS_TERMS_URL;


export const QuizSummary = ({ inApp = true }) => {
    const [summary, setSummary] = useState(null);
    const [quizComplete, setQuizComplete] = useState(false);
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState("");
    const [realtor, setRealtor] = useState("");
    const isDesktop = useDesktop();
    const isDesktopMini = useDesktopMini();
    const componentRef = useRef();

    useEffect(() => {
        const fetch = async () => {
            const base = await UserAPI.getBaseQuiz();
            const questions = await UserAPI.getAllQuestions();
            if (questions.length !== 24) {
                setLoading(false);
                setQuizComplete(false);
            } else {
                const sections = await UserAPI.getSectionResponse();
                const new_quiz = mergeResponses(base, questions, sections);
                const body = {
                    "sections": new_quiz
                }

                const response = await UserAPI.summary(body);
                setLoading(false);
                setSummary(response);
                setQuizComplete(true);
            }
        }
        fetch();
    }, [])

    const mergeResponses = (survey, questions, sections) => {
        const responseMap = questions.reduce((map, response) => {
            map[response.question] = response.response;
            return map;
        }, {});

        let index = 0;
        survey.forEach(section => {
            section.section_response = sections[index].response;
            section.questions.forEach(question => {
                if (responseMap[question.id] !== undefined) {
                    question.response = responseMap[question.id];
                    question.satisfied = true;
                    question.updated = true;
                }
            });
            index++;
        });

        return survey;
    }

    const generatePDF = async (event) => {
        event.preventDefault();
        if (email !== "") {
            const formData = new FormData();
            formData.append('user_email', email);  // Replace with actual user email
            formData.append('realtor_email', realtor);  // Replace with actual realtor email
            console.log(formData)
            for (let [key, value] of formData.entries()) {
                console.log(key, value);
            }
            if (realtor !== "" && email === realtor) {
                toast.error("Cannot be the same email");
                return;
            }

            toast.promise(
                UserAPI.emailSummary(formData),
                {
                    pending: 'Processing summary...',
                    success: 'Summary sent',
                    error: 'Failed to send summary'
                }
            );
        } else {
            toast.error("Provide an email to send summary")
        }
    };

    const Layout = !isDesktop && !isDesktopMini ? MobileLayout : MainLayout;

    return (
        <>
            {
                inApp ?
                    (
                        <Layout mainAddClass={!isDesktop && !isDesktopMini ? styles.mobile_main : ''}>
                            {!isDesktop && !isDesktopMini ? <MobileTopBar title={''} style={{ background: 'inherit' }} /> : null}
                            <Contents className={'d-flex flex-column flex-grow-1 pb-4 mx-auto'}>
                                <div ref={componentRef} className={loading ? 'container' : ''}>
                                    {loading ? (
                                        <>
                                            <h1 className={`mt-4 ${styles.title}`}>
                                                Loading your summary...it may take a minute
                                            </h1>
                                            <div>
                                                <div className="spinner-grow text-dark"
                                                    style={{ width: '5rem', height: '5rem' }}
                                                    role="status"></div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {quizComplete ? (
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    textAlign: 'left'
                                                }}>
                                                    <Col className={'py-4 px-3'}
                                                        style={{ backgroundColor: 'black', alignItems: 'center' }}>
                                                        <Img src={logo} />
                                                        <h4 className={'mt-4'} style={{ color: 'white' }}>Your Buyer
                                                            Preferences</h4>
                                                    </Col>
                                                    {summary && (
                                                        <Summary data={summary?.SummaryofHouseholdComposition} />)}
                                                    {summary && (<Location data={summary?.LocationAndConvenience} />)}
                                                    {summary && (<Priorities data={summary?.PropertyPriorities} />)}
                                                    {summary && (<RiskTolerance data={summary?.RiskTolerance} />)}
                                                    <div className={styles.send_bg} style={quizComplete ? {
                                                        display: 'flex',
                                                        justifyContent: 'center'
                                                    } : { display: 'none' }}>
                                                        <Card className={styles.formCard + " my-5"}
                                                            bgClass={styles.card_bg}>
                                                            <Card.Header className={styles.header}
                                                                style={{ color: 'white' }}>Send it to
                                                                yourself</Card.Header>
                                                            <Card.Body>
                                                                <form>
                                                                    <Col style={{ alignItems: 'center', }}>
                                                                        <Input
                                                                            className={`${styles.input} w-100 border rounded-pill`}
                                                                            type="email"
                                                                            placeholder="Your E-mail"
                                                                            defaultValue={email}
                                                                            onChange={setEmail}
                                                                            name="email"
                                                                            id="personal"
                                                                            required
                                                                        />
                                                                        <Input
                                                                            className={`${styles.input} w-100 border rounded-pill`}
                                                                            type="email"
                                                                            placeholder="Realtor Email (optional)"
                                                                            defaultValue={realtor}
                                                                            onChange={setRealtor}
                                                                            id="realtor"
                                                                        />
                                                                        <Button
                                                                            className={'py-2 mt-2'}
                                                                            style={{ color: 'white ' }}
                                                                            type="submit"
                                                                            onClick={generatePDF}
                                                                        >
                                                                            Send
                                                                        </Button>
                                                                    </Col>
                                                                </form>
                                                            </Card.Body>
                                                        </Card>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className={'mt-4'} style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '50px',
                                                    textAlign: 'center',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    <h1 className={styles.title}>
                                                        Please complete the quiz first and then we can provide a summary
                                                    </h1>
                                                    <Survey title={"Take Intake Quiz"} />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                <Row className={`${styles.footer} mt-auto py-3 w-100 gap-1 justify-content-center border-top py-2`}>
                                    <span>©2024 Homescore&nbsp; </span> | <Link to={DOCS_TERMS_URL}>&nbsp; Terms of
                                        Service&nbsp; </Link> |
                                    <Link to={DOCS_PRIVACY_URL}>&nbsp; Privacy Policy</Link>
                                </Row>
                            </Contents>
                        </Layout>
                    )
                    :
                    (
                        <>
                            <div ref={componentRef} className={styles.main}>
                                {loading ? (
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <h1 className={`my-5 ${styles.title}`}>
                                            Loading... will take less than a minute
                                        </h1>
                                        <div className='spinner-grow'>

                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {quizComplete ? (
                                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                                <Col className={'py-4 px-3'} style={{ backgroundColor: 'black', alignItems: 'center' }}>
                                                    <Img src={logo} />
                                                    <h4 className={'mt-4'} style={{ color: 'white' }}>Your Buyer Preferences</h4>
                                                </Col>
                                                {summary && (<Summary data={summary?.SummaryofHouseholdComposition} />)}
                                                {summary && (<Location data={summary?.LocationAndConvenience} />)}
                                                {summary && (<Priorities data={summary?.PropertyPriorities} />)}
                                                {summary && (<RiskTolerance data={summary?.RiskTolerance} />)}
                                                <div>
                                                    <div className={styles.send_bg} style={quizComplete ? {} : { display: 'none' }}>
                                                        <Col style={{ alignItems: 'center' }}>
                                                            <Card className={styles.formCard + " my-5"} bgClass={styles.card_bg}>
                                                                <Card.Header className={styles.header} style={{ color: 'white' }}>Send it to yourself</Card.Header>
                                                                <Card.Body>
                                                                    <form>
                                                                        <Col style={{ alignItems: 'center', }}>
                                                                            <Input
                                                                                className={`${styles.input} w-100 border rounded-pill`}
                                                                                type="email"
                                                                                placeholder="Your E-mail"
                                                                                onChange={setEmail}
                                                                                name="email"
                                                                                id="personal"
                                                                                required
                                                                            />
                                                                            <Input
                                                                                className={`${styles.input} w-100 border rounded-pill`}
                                                                                type="email"
                                                                                placeholder="Realtor Email (optional)"
                                                                                onChange={setRealtor}
                                                                                id="realtor"
                                                                            />
                                                                            <Button
                                                                                className={'py-2 mt-2'}
                                                                                style={{ color: 'white ' }}
                                                                                type="submit"
                                                                                onClick={generatePDF}
                                                                            >
                                                                                Send
                                                                            </Button>
                                                                        </Col>
                                                                    </form>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div style={{ height: '50rem', display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                                                <Survey title={"Take Intake Quiz"} starting={true} />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </>
                    )
            }
        </>
    )
}

QuizSummary.propTypes = {
    inApp: PropTypes.bool,
}