import PropTypes from 'prop-types';
import React from "react";

import styles from './slimCardItem.module.scss';

import {Img} from "@/components/Layout";
import {useDesktop, useDesktopMini, useMobileExtraMini, useMobileMini} from "@/hooks/useAdaptives";



export const SlimCardItem = ({item}) => {
    const isDesktop = useDesktop();
    const isDesktopMini = useDesktopMini();
    const isMobileExtraMin = useMobileExtraMini();
    const isMobileMini = useMobileMini();

    const halfWidth = isMobileMini ? '48%' : '49%';

    return(
        <div className={!isDesktop && !isDesktopMini ? `${styles.mobile_item}  p-2` : `col-6 col-md-3 p-2`}
             style={{width: !isDesktop && !isDesktopMini ? ([2,3].includes(item.id) ? halfWidth : '100%') : ''}}>
            <div className='row row-cols-2'>
                <div className='col col-8 pe-0'>
                    <div className={'d-flex'}>
                        <Img src={item.smallImg} style={{ marginRight: '8px' }} className={'align-self-start'}/>
                        <div>
                            <h2 className={`${styles.h2} mb-4`}>{item.title}</h2>
                            <h3 className={`${styles.h3} text-start`}>{`${item.title === 'Price' ? '$' : ''}${item.value}`}</h3>
                        </div>
                    </div>
                </div>
                <div className='col col-4  align-self-center ps-0'>
                    <Img src={item.bigImg} className={'mw-100'}/>
                </div>
            </div>
        </div>
    )
}

SlimCardItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number,
        smallImg: PropTypes.string,
        bigImg: PropTypes.string,
        title: PropTypes.string,
        value:  PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])
    }),
}