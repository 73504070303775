import { track } from '@vercel/analytics/react';

import { api } from './config';

export const KanbanState = {
    CONSIDERING: 'Considering',
    VIEWED: 'Viewed',
    AWAITING_DETAILS: 'Awaiting Details',
    SHORTLISTED: 'Shortlisted'
}

export const ReportAPI = {
    async getReports() {
        const response = await api.get('/reports/reports/');
        const data = response.data;
        for (const report of data) {
            if (report?.summary) {
              //  report.summary = JSON.parse(report.summary);
                try {
                    report.summary = JSON.parse(report.summary);
                } catch (error) {
                    console.log(error)
                }
            }
            if (report?.details) {
                report.details = JSON.parse(report.details);
            }
        }
        data.sort((a, b) => a.id - b.id);
        return data;
    },

    async getReport(reportId) {
        const response = await api.get(`/reports/reports/${reportId}`);
        const data = response.data;
        if (data?.summary) {
           // data.summary = JSON.parse(data.summary);
            try {
                data.summary = JSON.parse(data.summary);
            } catch (error) {
                console.log(error)
            }
        }
        if (data?.details) {
            data.details = JSON.parse(data.details);
        }
        return data;
    },

    async getReportUrl(reportId) {
        const response = await api.get(`/reports/report_pdf/${reportId}`);
        const data = response.data;
        // console.log(data)
        return data?.url;
    },

    async getReportIssues(reportId) {
        const response = await api.get(`/reports/issues/${reportId}`)
        return response.data;
    },

    async getPreferences(reportId) {
        const response = await api.get(`/reports/preferences/${reportId}`);
        return response.data;
    },

    async getFitScore(reportId) {
        const response = await api.get(`/reports/fit_score?report_id=${reportId}`);
        return response.data;
    },

    async uploadNew(address, file = null, config = {}) {
        const formData = new FormData();
        formData.append('address', address);
        if (file) formData.append('file', file);

        const response = await api.post('/reports/upload/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            ...config,
        });

        track("Adding New Home");

        return response.data;
    },

    async updateReport(reportId, file, config = {}) {
        const formData = new FormData();
        formData.append('file', file);

        const response = await api.post(`/reports/reports/${reportId}/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            ...config,
        });

        track("Adding New Report");

        return response.data;
    },

    async getIssues(propertyId) {
        const response = await api.get(`/reports/issues/${propertyId}`);
        return response.data;
    },

    async sendMessage(content, chatId, issue) {
        const formData = new FormData();
        formData.append('content', content);
        formData.append('message_type', "HUMAN");
        formData.append('chat', chatId);
        formData.append('issue', issue)
        const response = await api.post(`/chat/chat/${chatId}/new_message/`, formData);
        return response.data;
    },


    async checkBotResponse(taskId, chatId) {
        const response = await api.get(`/chat/chat/${chatId}/get_response/${taskId}/`);
        return response.data;
    },

    async fetchOrCreateChat(report_id, issue_name) {
        const response = await api.get(`/chat/chat/list/?report=${report_id}&issue=${issue_name}`);
        if (response.data.length == 0) {
            const formData = new FormData();
            formData.append('report', report_id);
            formData.append('issue', issue_name)
            const response = await api.post(`/chat/chat/create/`, formData);
            return response.data
        } else {
            return response.data[0];
        }
    },

    async getChatCardPlaces(issue, address) {
        const response = await api.get(`/reports/places?issue=${issue}&address=${address}`);
        return response.data
    },

    async getCardPlaceDetails(placeId) {
        const response = await api.get(`/reports/place_details?place_id=${placeId}`)
        return response.data
    },

    async getChartComparison(reportId1, reportId2) {
        const response = await api.get(`/reports/compare?ids=${reportId1}&ids=${reportId2}`)
        return response.data;
    },

    async getCollabrators(reportId) {
        const response = await api.get(`/reports/collaborators/${reportId}`)
        return response.data;
    },

    async inviteCollabrators(reportId, collaborator) {
        const response = await api.post(`/reports/collaborators/${reportId}`, { collaborator })
        return response.data;
    },

    async removeHome(reportId) {
        const response = await api.delete(`reports/reports/${reportId}/delete/`);
        return response.data;
    },

    async promoteKanbanCardStatus(reportId) {
        const response = await api.post(`reports/reports/${reportId}/promote/`);
        return response.data;
    },

    async demoteKanbanCardStatus(reportId) {
        const response = await api.post(`reports/reports/${reportId}/demote/`);
        return response.data;
    },

    //rooms
    async getRooms(reportId) {
        const response = await api.get(`/reports/room_details_updated/${reportId}`);
        return response
    },

    async getRoomsPictures(reportId) {
        const response = await api.get(`/reports/image_urls/${reportId}`);
        return response
    }


}