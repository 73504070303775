import PropTypes from 'prop-types'
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';

const ItemType = 'ITEM';

// Draggable Item Component
const DraggableItem = ({ id, name, moveItem, rank, setRank, className = "", style = {} }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemType,
        item: { id },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: ItemType,
        drop: (draggedItem) => {
            if (draggedItem.id !== id) {
                moveItem(draggedItem.id, id, rank, setRank);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const combinedStyle = {
        ...style,
        opacity: isDragging || (isOver && canDrop) ? 0.5 : 1,
        cursor: isDragging ? 'grabbing' : 'pointer',
    };

    return (
        <div ref={(node) => drag(drop(node))} className={className} style={combinedStyle}>
            {name}
        </div>
    );
};

DraggableItem.propTypes = {
    id: PropTypes.any.isRequired,
    name: PropTypes.any.isRequired,
    moveItem: PropTypes.func.isRequired,
    rank: PropTypes.array.isRequired,
    setRank: PropTypes.func.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
};

// Droppable Area Component
const DroppableArea = ({ items, moveItem, rank, setRank, itemClass = "", itemStyle = {} }) => {
    return (
        <div>
            {items.map((item) => (
                <DraggableItem key={item.id} id={item.id} name={item.name} moveItem={moveItem} rank={rank} setRank={setRank} className={itemClass} style={itemStyle} />
            ))}
        </div>
    );
};

DroppableArea.propTypes = {
    items: PropTypes.array.isRequired,
    moveItem: PropTypes.func.isRequired,
    rank: PropTypes.array.isRequired,
    setRank: PropTypes.func.isRequired,
    itemClass: PropTypes.string,
    itemStyle: PropTypes.object,
};

export { DraggableItem, DroppableArea };
