import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {track} from '@vercel/analytics/react';
import {toast} from "react-toastify";

import {dispatch} from '..';

import {UserAPI} from '@/api/user';



const initialState = {
    loading: false,
    isLoggedIn: false,
    data: null,
    isInitialized: false,
};

/**
 * Try initializing user if it's logged in
 */
export const initializeUser = createAsyncThunk('user/initialize', async (_, {dispatch}) => {
    dispatch(setUserData(await UserAPI.me()));
    await UserAPI.setCSRF();
});

export const loginUser = createAsyncThunk('user/login', async ({username, email, password}, {dispatch}) => {
    dispatch(setLoading(true))
    await UserAPI.setCSRF();
    await UserAPI.login(username, email, password);
    dispatch(setUserData(await UserAPI.me()));
    dispatch(setLoading(false));
    toast.success('Welcome back to HomeScore!');
    track('Returning User');
});

export const signupUser = createAsyncThunk('accounts/signup', async ({
                                                                         username,
                                                                         email,
                                                                         password1,
                                                                         password2,
                                                                     }, {dispatch}) => {

    dispatch(setLoading(true))
    await UserAPI.setCSRF();
    await UserAPI.register(username, email, password1, password2);
    dispatch(setUserData(await UserAPI.me()));
    dispatch(setLoading(false));
    toast.success('Registration was successful!');
    track('New User');
});

export const logoutUser = createAsyncThunk('user/logout', async (_, {dispatch}) => {
    try {
        await UserAPI.logout();
    } finally {
        dispatch(logout());
        track('User Logging Out')
    }
});

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        logout: state => {
            state.isInitialized = true;
            state.isLoggedIn = false;
            state.data = null;
            localStorage.setItem('isLoggedIn', 'false');
        },
        setUserData: (state, {payload}) => {
            state.isInitialized = true;
            state.data = payload;
            state.isLoggedIn = true;
            localStorage.setItem('isLoggedIn', 'true');
        },
    },
});

export const {logout, setUserData, setLoading} = userSlice.actions;

export const userReducer = userSlice.reducer;
