let navigate;

export const setNavigate = (navFunction) => {
    navigate = navFunction;
};

export const navigateTo = (path) => {
    if (navigate) {
        navigate(path);
    } else {
        console.error("Navigate function is not defined");
    }
};