import { Warning } from '@phosphor-icons/react';
import PropTypes from 'prop-types';

import styles from './index.module.scss'

import {Button, Col, Row, Modal} from '@/components/Layout';

export const WarnUpload = ({ isOpen, onClose = () => { }, onNext = () => { } }) => {
    return (
            <Modal isOpen={isOpen} onClose={onClose} title={"Upload Inspection Report"}>

                    <Col className={'w-100 align-items-center'} style={{maxWidth: '560px'}}>
                        <p className={styles.description}>Unlock personalized insights & cost estimates</p>
                        <Col className={`${styles.warning} align-items-center py-4 mb-2`}>
                            <Row className={styles.price_block}>
                                <Warning style={{ display: 'inline', color: 'white' }} size={'6rem'} />
                                <h1 className={`${styles.price_tag} ms-2`}>$14,000</h1>
                            </Row>
                            <p className={styles.tag}>The average amount buyers save by using their inspection report to negotiate an offer.</p>
                        </Col>
                        <Button className={styles.continueBtn} onClick={() => (onNext())}>Upload Report</Button>
                    </Col>

            </Modal>
    )
}

WarnUpload.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
}