import PropTypes from "prop-types";
import React, { useState } from "react";
import Gravatar from "react-gravatar";
import ReactMarkdown from 'react-markdown';

import styles from './chatComponent.module.scss';

import { UserAPI } from "@/api/user";
import botImg from "@/assets/icons/botImg.svg";
import send from "@/assets/icons/sendMessage.svg";
import xThin from "@/assets/icons/xThin.svg";
import { Button, Col, Img, Row, Icon, Input } from "@/components/Layout";
import { ColorAccent } from "@/constants";


export const ChatComponent = ({ chat, setChat, chatContainerRef, chatMessages, isWaitingForBotResponse, loading, message,
    handleMessage, handleMessageSend, handleKeyDown }) => {
    const [userEmails, setUserEmails] = useState(Object());

    const getUserEmail = (user) => {
        if (user in userEmails) {
            return userEmails[user];
        } else {
            UserAPI.getUser(user).then(data => {
                setUserEmails({ ...userEmails, [user]: data.email });
                return data.email;
            })
        }
    }

    return (
        <Col className={`flex-grow-1 w-100`} style={chat ? {} : { display: 'none' }}>
            <Row className={'w-100 p-3 border-bottom border-2 flex-nowrap'}>
                <h2>HomeScore AI Chatbot</h2>
                <Button className={'cursor-pointer border-0'} colorAccent={ColorAccent.white} onClick={() => setChat(false)}>
                    <Icon src={xThin} size={24} />
                </Button>
            </Row>
            {/* chat start */}
            <div id="chatmessage" ref={chatContainerRef} className={styles.chatMessages}>
                {chatMessages.map((msg, index) => (
                    <div key={index}
                        className={'d-flex align-items-end my-2 mx-0 gap-2'}
                        style={{
                            justifyContent: msg.message_type === "AI" ? "" : "end",
                            flexDirection: msg.message_type === "AI" ? "" : "row-reverse"
                        }}>
                        <div className={msg.message_type === "AI" ? 'ms-2' : 'me-2'}>
                            {msg.message_type === "AI" ?
                                <Img src={botImg} className={styles.profileImage} /> :
                                <Gravatar email={getUserEmail(msg.user)}
                                    className={styles.profileImage} />}
                        </div>
                        <div className={`${styles.messageBubble} rounded-3 p-2 text-start`}
                            style={{ backgroundColor: msg.message_type === "AI" ? "#EEF0F6" : "#D0E6FF" }}>
                            <ReactMarkdown className={styles.no_split_text}>{msg.content}</ReactMarkdown>
                        </div>
                    </div>
                ))}
                <div
                    style={isWaitingForBotResponse ? { display: 'flex' } : { display: 'none' }}
                    className={'align-items-end my-2 mx-0 gap-2'}>
                    <div className={styles.botIcon}>
                        <Img src={botImg} className={styles.profileImage} />
                    </div>
                    <div className={styles.messageBubble}>
                        {loading}
                    </div>
                </div>
            </div>
            {/* chat end */}

            <Row className={`${styles.chat1} flex-nowrap p-2 border border-2 rounded-pill border-dark position-relative mx-auto`}>
                <Input placeholder='Type your message here...'
                    autoFocus
                    value={message}
                    onChange={handleMessage}
                    onKeyDown={handleKeyDown}
                    className={'w-100 px-3'} style={{ fontSize: '1.125rem' }} />
                <Img src={send} alt="Send" className={'cursor-pointer'} onClick={() => handleMessageSend()} />
            </Row>
        </Col>
    )
}

ChatComponent.propTypes = {
    chat: PropTypes.bool,
    setChat: PropTypes.func,
    chatContainerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    chatMessages: PropTypes.array,
    isWaitingForBotResponse: PropTypes.bool,
    loading: PropTypes.string,
    message: PropTypes.string,
    handleMessage: PropTypes.func,
    handleMessageSend: PropTypes.func,
    handleKeyDown: PropTypes.func,
};