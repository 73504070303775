import {Dialog, DialogActions, DialogContent, styled} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const CustomMuiDialogWindow = ({
                                   children,
                                   open = () => {
                                   },
                                   onClose = () => {
                                   },
                                   dialogStyles = {},
                                   dialogContentStyles = {},
                                   backdrop = {},
                               }) => {

    const CustomDialog = styled(Dialog)(({...props}) => ({
        '& .MuiDialog-paper': {
            ...dialogStyles
        },
    }));

    const CustomDialogContent = styled(DialogContent)({
        ...dialogContentStyles
    });

    return (
        <CustomDialog open={open} onClose={onClose} BackdropProps={{
            style: backdrop,
        }}>
            <CustomDialogContent>
                {children}
            </CustomDialogContent>
        </CustomDialog>
    )
}

CustomMuiDialogWindow.propTypes = {
    children: PropTypes.node,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    dialogStyles: PropTypes.object,
    dialogContentStyles: PropTypes.object,
    backdrop: PropTypes.object,
}
export default CustomMuiDialogWindow;