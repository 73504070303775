import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Chart as ChartJS, Filler, LineElement, PointElement, RadarController, RadialLinearScale } from 'chart.js';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { CircularProgressbar } from 'react-circular-progressbar';

import styles from './main.module.scss';

import { ReportAPI } from '@/api/report';
import xShape from '@/assets/icons/xShape.svg';
import Card, { CardType } from '@/components/Card';
import { Col, Flex, Row } from '@/components/Layout';
import { ColorAccent } from '@/constants';
import { getReportAddresses } from '@/utils';

const THRESH_WEAK_FIT = parseFloat(process.env.REACT_APP_THRESH_WEAK_FIT);
const THRESH_PARTIAL_FIT = parseFloat(process.env.REACT_APP_THRESH_PARTIAL_FIT);

ChartJS.register(RadarController, RadialLinearScale, PointElement, LineElement, Filler);
const chartOptions = {
    maintainAspectRatio: false,
    layout: {
        padding: {
            left: 20,
            right: 20,
        },
    },
    scales: {
        r: {
            grid: {
                circular: true,
            },
            beginAtZero: true,
            pointLabels: {
                font: {
                    size: window.innerWidth <= 625 ? 11 : 16,
                    family: 'Poppins',
                    weight: 400,
                },
            },
            min: 0,
            max: 10,
            ticks: {
                stepSize: 2, // the number of step
            },
        },
    },
    elements: {
        line: {
            tension: 0.1,
            borderWidth: 2,
            borderCapStyle: 'round',
            borderJoinStyle: 'round',
            fill: true,
            borderColor: 'rgba(19, 171, 53, 0.54)',
            backgroundColor: 'rgba(19, 171, 53, 0.06)',
            shadowBlur: 3,
            shadowOffsetX: 3,
            shadowOffsetY: 10,
        },
        point: {
            radius: 0,
        },
    },
};
const responsiveManager = () => {
    if (window.innerWidth >= 1438) {
        return 4
    } else if (window.innerWidth < 1438 && window.innerWidth > 1094) {
        return 2
    } else if (window.innerWidth < 1094) {
        return 0
    } else {
        return 0
    }
}
export const Body = ({ report1, report2, comparison }) => {
    const [chartData, setChartData] = useState(null);
    const [mergeData, setMergeData] = useState({
        labels: [],
        datasets: []
    })
    const [loading, setLoading] = useState(false);
    const [fitScores, setFitScores] = useState([]);
    const [comparisonCards, setComparisonCards] = useState([])
    const [arraySize, setArraySize] = useState(responsiveManager());
    const [modelLoading, setModelLoading] = useState(false);

    const addressDetails = report1?.details?.addressDetails;

    const getChartPreferences = async (reportId1, reportId2) => {
        setModelLoading(true)
        ReportAPI.getChartComparison(reportId1, reportId2).then((chartData) => {
            setChartData(chartData.preferences)
            chartData.comparison.differences.map((a) => {
                a.score_difference = a.score_difference
            });

            setComparisonCards(chartData.comparison.differences)
            getFitScore(comparison[0], comparison[1]);
            setModelLoading(false);
        }).catch(() => {
            setModelLoading(false);
        });
    }

    const getFitScore = async (propertyId1, propertyId2) => {
        setModelLoading(true)
        const score1 = await ReportAPI.getFitScore(propertyId1);
        const score2 = await ReportAPI.getFitScore(propertyId2);
        setModelLoading(false);
        setFitScores([score1.fit_score, score2.fit_score]);
    };

    useEffect(() => {
        function handleResize() {
            setArraySize(responsiveManager);
        }

        // Add event listener to window object
        window.addEventListener("resize", handleResize);

        // Clean up the event listener when component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [])

    useEffect(() => {
        if (comparison.length === 2) {
            getChartPreferences(comparison[0], comparison[1]);
        }
    }, [comparison.length]);

    useEffect(() => {
        if (chartData && chartData[comparison[0]] && chartData[comparison[1]]) {
            setMergeData({
                labels: chartData[comparison[0 || 1]]?.map(p => p.preference),
                datasets: [
                    {
                        label: 'Property 1 Preferences',
                        data: chartData[comparison[0]]?.map(p => p.score || 0) || [],
                    },
                    {
                        label: 'Property 2 Preferences',
                        data: chartData[comparison[1]]?.map(p => p.score || 0) || [],
                        borderColor: '#2875AD',
                        backgroundColor: 'rgba(0, 0, 0, 0.06)'
                    }
                ]
            })
        }
    }, [chartData, comparison[0], comparison[1]])

    return (
        <>
            <Backdrop
                sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={modelLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Row className={`${styles.cards} align-items-start flex-column gap-3 mb-3`}>

                <div className={'w-100 d-flex gap-3'}>
                    <Col className={styles.chartCardContainer}>
                        <Card type={CardType.bigBox} className={'w-100 overflow-hidden flex-grow-1'}>
                            <Card.Header icon={xShape}>
                                <span>Overview</span>
                            </Card.Header>
                            <Card.Body className={'w-100 d-flex flex-column flex-grow-1'}>
                                <Row className={'w-100 gap-1 justify-content-between'}>
                                    <Col className={styles.chartGreenCurve}>
                                        <p className={'m-0'}>{getReportAddresses(report1).address2}</p>
                                        <h2 className={styles.fit}>
                                            {fitScores[0] !== undefined ? (
                                                fitScores[0] >= THRESH_PARTIAL_FIT ? 'Strong Fit' :
                                                    (fitScores[0] >= THRESH_WEAK_FIT ? 'Okay Fit' : 'Weak Fit')
                                            ) : ''}
                                        </h2>
                                    </Col>
                                    <div className={styles.chartBlueContent}>
                                        <Col className={`${styles.chartBlueCurve} text-start py-2 px-3`}>
                                            <p className={`${styles.cardContentCruve} m-0 text-end`}>{getReportAddresses(report2).address2}</p>
                                            <h2 className={'m-0'}> {fitScores[1] !== undefined ? (
                                                fitScores[1] >= THRESH_PARTIAL_FIT ? 'Strong Fit' :
                                                    (fitScores[1] >= THRESH_WEAK_FIT ? 'Okay Fit' : 'Weak Fit')
                                            ) : ''}</h2>
                                        </Col>
                                    </div>
                                </Row>
                                {chartData && chartData[comparison[0]] && chartData[comparison[1]] ? (
                                    <div className={'w-100 d-flex position-relative flex-grow-1'}>
                                        <div className={'w-100'}>
                                            <Chart type="radar" options={chartOptions} data={mergeData}
                                                className={'w-100 h-100'} />
                                        </div>
                                    </div>
                                ) : null}
                            </Card.Body>
                        </Card>
                    </Col>

                    <Flex className={`${styles.chartCardAlign} flex-wrap justify-content-start`} style={{ display: `${!arraySize ? 'none' : ''}` }}>
                        {comparisonCards &&
                            comparisonCards.slice(0, arraySize).map((p, index) => (
                                <Card
                                    type={CardType.smallBox}
                                    key={index}
                                    className={styles.preferenceCard}
                                    colorAccent={
                                        parseInt(p.score_difference) > 4 ? ColorAccent.orange : parseInt(p.score_difference) > 2 ? ColorAccent.blue : ColorAccent.green
                                    }
                                >
                                    <Card.Header icon={xShape}>{p.preference
                                    }</Card.Header>
                                    <Card.Body>
                                        <div className={styles.circleScore}>
                                            <CircularProgressbar value={p.score_difference} maxValue={10}
                                                text={`${p.score_difference}/10`} />
                                        </div>
                                        <div className={styles.rationale}>
                                            {p.qualitative_difference}
                                        </div>
                                    </Card.Body>
                                </Card>
                            ))}

                    </Flex>
                </div>
                <div style={{
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    {comparisonCards.length > arraySize &&
                        comparisonCards.slice(arraySize).map((p, index) => (
                            <Card
                                type={CardType.smallBox}
                                key={index}
                                className={styles.preferenceCard}
                                colorAccent={
                                    parseInt(p.score_difference) > 4 ? ColorAccent.orange : (parseInt(p.score_difference) > 2 ? ColorAccent.blue : ColorAccent.green)
                                }
                            >
                                <Card.Header icon={xShape}>{p.preference
                                }</Card.Header>
                                <Card.Body>
                                    <div className={styles.circleScore}>
                                        <CircularProgressbar value={parseInt(p.score_difference)} maxValue={10}
                                            text={`${p.score_difference}/10`} />
                                    </div>
                                    <div className={styles.rationale}>
                                        {p.qualitative_difference}
                                    </div>
                                </Card.Body>
                            </Card>
                        ))}
                </div>
            </Row>
        </>

    )
}

Body.propTypes = {
    report1: PropTypes.object,
    report2: PropTypes.object,
    comparison: PropTypes.array
}

export const Controls = props => <></>;