import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, NavLink } from "react-router-dom";

import styles from "./propertyOrganization.module.scss";

import { ReportAPI } from "@/api/report";
import { UserAPI } from "@/api/user";
import compare from '@/assets/propertyOrganization/compare.png';
import home_guide from '@/assets/propertyOrganization/home_guide.png';
import main_image from '@/assets/propertyOrganization/main_image.png';
import property_insights from '@/assets/propertyOrganization/property_insights.png';
import quiz from '@/assets/propertyOrganization/quiz_large.png';
import upload from '@/assets/propertyOrganization/upload_large.png';
import vector from '@/assets/propertyOrganization/vector.png';
import { UploadModal } from "@/components/HouseCard/UploadModal";
import { Col, Icon, MobileLayout } from "@/components/Layout";
import MobileTopBar from "@/components/mobileTopBar/mobileTopBar";
import { Survey } from "@/components/v1Survey";
import { useDesktop, useDesktopMini, useMobileMini } from "@/hooks/useAdaptives";
import { dispatch } from "@/store";
import { chooseReport } from "@/store/reports/reportsSlice";
import { getAllRooms, getRoomsPhotos } from "@/store/rooms/roomsSlice";


const PropertyOrganizationPage = () => {
    const { chosenReport } = useSelector(state => state.reports);
    const image = chosenReport?.details.imgSrc;
    const [uploadOpen, setUploadOpen] = useState(false);
    const isDesktop = useDesktop();
    const isDesktopMini = useDesktopMini();
    const isMobileMini = useMobileMini();
    //for quiz button
    const [userPreferences, setUserPreferences] = useState(null);

    const sectionLinks = [
        {
            id: 1,
            title: 'Home Guide',
            link: `${chosenReport?.room_summary_status !== 'Done' ? '#' : '/rooms'}`,
            image: home_guide
        },
        {
            id: 2,
            title: 'Property Insights',
            link: `${chosenReport?.details_status !== 'Done' ? '#' : `/my-properties/insights/${chosenReport?.id}`}`,
            image: property_insights
        },
        { id: 3, title: 'Compare', link: '/comparison', image: compare }
    ]

    useEffect(() => {
        chosenReport && dispatch(getAllRooms({ reportId: chosenReport?.id }))
    }, [])

    useEffect(() => {
        chosenReport && dispatch(getRoomsPhotos({ reportId: chosenReport?.id }))
    }, [])

    useEffect(() => {
        let interval = chosenReport && chosenReport?.room_summary_status !== 'Done' ? setInterval(() => {
            ReportAPI.getReport(chosenReport.id).then(data => {
                if (data.room_summary_status === 'Done') {
                    dispatch(chooseReport(data))
                    clearInterval(interval);
                }

            })
        }, 12000) : null

        return () => {
            interval && clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        UserAPI.getUserPreferences().then(data => {
            setUserPreferences(data.length);
        })
    }, [])

    return (
        !isDesktop && !isDesktopMini && chosenReport
            ? <MobileLayout mainAddClass={!isDesktop && !isDesktopMini ? styles.mobile_main : ''}>
                <UploadModal style={chosenReport?.details.mlsid ? {} : { display: 'none' }}
                    report={chosenReport} isOpen={uploadOpen}
                    onClose={() => setUploadOpen(false)} />

                <Col className={'w-100 justify-content-start'}>
                    <MobileTopBar link={'/'} style={{ backgroundColor: 'inherit' }} />
                    <div className={styles.main_image_block}>
                        {/*<img src={image} alt="" />*/}
                        <img src={image ? image : main_image} alt="" />
                        <div className={styles.info_block}>
                            <div className={styles.status}>
                                <p>{chosenReport?.status || 'Considering'}</p>
                                <div className={styles.address}>
                                    <Icon src={vector} />
                                    <p>{chosenReport?.name}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Col className={`${styles.section_links_wrapper} gap-3`}>
                        {
                            sectionLinks.map((link, index) => {
                                return (
                                    <NavLink key={index}
                                        className={styles.link_wrapper}
                                        to={link.link} style={{
                                            opacity: link.link === '#' ? '.7' : '1',
                                            filter: link.link === '#' ? 'grayscale(100%)' : ''
                                        }}>
                                        <img src={link.image} alt="" />
                                        <div className={styles.link_wrapper_title}>{link.title}</div>
                                        {
                                            link.link === '#' ?
                                                <div className={`${styles.loader} spinner-border`}
                                                    style={{ width: '3rem', height: '3rem' }} role="status"></div>
                                                : null}
                                    </NavLink>
                                )
                            })
                        }

                        {typeof userPreferences === 'number' && userPreferences < 8 ?
                            <Survey className={styles.link_wrapper} title={
                                <>
                                    <img src={quiz} alt="" />
                                    <div className={styles.link_wrapper_title}>Homebuyer Quiz</div>
                                </>
                            } /> : null}

                        {!chosenReport?.has_report &&
                            <div className={styles.link_wrapper} onClick={e => (e.preventDefault(), setUploadOpen(true))}>
                                <img src={upload} alt="" />
                                <div className={styles.link_wrapper_title}>Upload Docs</div>
                            </div>}
                    </Col>
                </Col>
            </MobileLayout>
            : <Navigate to={'/'} />
    )
}

export default PropertyOrganizationPage;