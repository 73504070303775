import PropTypes from 'prop-types';
import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useNavigate } from 'react-router-dom';

import styles from './Upload.module.scss';

import { ReportAPI } from '@/api/report';
import fileIcon from '@/assets/icons/file.svg';
import plusCircle from '@/assets/icons/plusCircle.svg';
import {Button, Col, Icon, Row, Modal} from '@/components/Layout';
import { formatNumber, getReportAddresses, humanFileSize } from '@/utils';

export const UploadModal = ({ report, isOpen, onClose = () => { } }) => {
    const { address2 } = getReportAddresses(report);
    const insightsLink = `/my-properties/insights/${report?.id}`;
    const [progress, setProgress] = useState(0);
    const [abortController, setAbortController] = useState(null);
    const [files, setFiles] = useState([]);
    const [enabled, setEnabled] = useState(true)
    const closeHandler = () => {
        setProgress(0);
        setFiles([]);
        onClose();
    };

    const navigate = useNavigate();

    const onUploadProgress = progressEvent => {
        setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
    };
    const setFile = file => {
        setFiles([file]);
    };

    const sendFile = async () => {
        if (abortController) {
            try {
                abortController.abort();
            } catch (e) { }
            setAbortController(null);
        }
        const controller = new AbortController();
        setAbortController(controller);
        setEnabled(!enabled);
        await ReportAPI.updateReport(report.id, files[0], { onUploadProgress, signal: controller.signal });
        navigate(insightsLink);
    }

    return (
            <Modal onClose={closeHandler} isOpen={isOpen} title="Upload files">
                <Col className={'w-100 align-items-center'} style={{maxWidth: '440px'}}>
                    <div className={styles.description}>
                        <p>You can upload 1 file</p>
                        <p>for {address2}</p>
                    </div>

                    <FileUploader
                        maxFiles={8}
                        maxSize={10000000}
                        minSize={0}
                        readAs="DataURL"
                        types={['pdf']}
                        handleChange={setFile}
                        classes={`${styles.fileUploader} d-flex flex-column align-items-center cursor-pointer mt-4 mb-2`}
                    >
                        <Icon src={plusCircle} size={24} />
                        <h3>Drop your files here</h3>
                        <p>
                            <u>Browse</u> file from your computer
                        </p>
                    </FileUploader>

                    {files.map(file => (
                        <Row key={file} className={`${styles.fileProgress} w-100 align-items-start gap-3 my-2 mx-0 py-2 px-3`}>
                           <Icon src={fileIcon} size={28} />
                            <Col className={'flex-grow-1'}>
                                <span className={styles.name}>{file.name}</span>
                                <span className={styles.size}>{humanFileSize(file.size)}</span>
                                <Row className={styles.progress}>
                                    <div className={styles.bar}>
                                        <div style={{ width: `${progress}%` }} />
                                    </div>
                                    <span>{formatNumber(progress)}%</span>
                                </Row>
                            </Col>
                        </Row>
                    ))}
                    <Button disabled={!enabled} className={`${styles.continueBtn} mt-4`} onClick={() => { sendFile() }}>{enabled ? 'Upload' : 'Uploading....'}</Button>
                </Col>
            </Modal>
    );
};

UploadModal.propTypes = {
    report: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
};
