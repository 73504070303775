import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ReportAPI } from '@/api/report';

const initialState = {
    reports: [],
    demo: false,
    warn: false,
    onboarding: false,
    chosenReport: null,
    // tabForMovingReports: null,
};

//thunk for getting all reports and save it in global state
export const getAllReports = createAsyncThunk(
    'reports/getAllReports',
    async ({ checkWarn = false, firstLoading = false }, { dispatch }) => {
        const reports = await ReportAPI.getReports();
        //for showing demo on first loading if we have less than 1 report
        firstLoading && reports.length <= 1 && dispatch(setDemo({ demo: true }));
        firstLoading && dispatch(setOnboarding({onboarding: true}));
        //every time we add new report we will check amount of properties with reports
        //and will show warn modal if its less than 2
        if (checkWarn) {
            const has_reports = reports.filter(report => report.has_report);
            has_reports.length < 2 && dispatch(setWarn({ warn: true }))
        }
        //!!пока статусы руками добавила
        // const newReports = reports.map((r, index) => index <= 2 ? {...r, status: 'Considering'}
        //     : index <= 5 ?  {...r, status: 'Viewed'} : index <= 7 ? {...r, status: 'Awaiting Details'} : {...r, status: 'Shortlisted'})
        dispatch(getReports(reports))
    }
)

export const removeHome = createAsyncThunk(
    'reports/removeReport',
    async ({ reportId, fromGlobalState = false, setLocalState }, { dispatch }) => {
        ReportAPI.removeHome(reportId).then(res => {
            fromGlobalState && dispatch(removeReport(reportId));
            setLocalState && setLocalState();
        })
    }
)

export const moveCardOnKanban = createAsyncThunk(
    'reports/moveCard',
    async ({ direction, reportId }, { dispatch }) => {
        const sendReq = direction === 'right' ? () => ReportAPI.promoteKanbanCardStatus(reportId)
            : () => ReportAPI.demoteKanbanCardStatus(reportId);
        sendReq().then(res => {
            dispatch(getAllReports({}))
        })
    }
)

export const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        getReports: (state, action) => {
            state.reports = action.payload
        },
        removeReport: (state, action) => {
            state.reports = state.reports.filter(r => r.id !== action.payload)
        },
        setDemo: (state, action) => {
            state.demo = action.payload.demo
        },
        setWarn: (state, action) => {
            state.warn = action.payload.warn
        },
        setOnboarding: (state, action) => {
            state.onboarding = action.payload.onboarding
        },
        // updateReportStatus: (state, action) => {
        //     let reportsId = action.payload.map(r => r.reportId);
        //     state.reports = state.reports.map(r => {
        //         if(reportsId.includes(r.id)) {
        //             let newS = action.payload.find(p => p.reportId === r.id).newStatus;
        //             return ({...r, status: newS})
        //         } else return r;
        //     })
        // },
        chooseReport: (state, action) => {
            state.chosenReport = action.payload
        },
        clearState: state => {
            state.reports = []
        }
    },
    extraReducers: (builder) => {

    }
})

export const { getReports, clearState, removeReport, setDemo, setWarn, updateReportStatus, chooseReport, setOnboarding } = reportsSlice.actions;

export const reportsReducer = reportsSlice.reducer;