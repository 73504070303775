import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { track } from '@vercel/analytics/react';
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { LiveAudioVisualizer } from 'react-audio-visualize';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
// import { useNavigate } from 'react-router-dom';

import styles from './Quiz.module.scss';
import { DroppableArea } from '../DragNDrop';
import { Modal, Input, Button, Col, Img } from '../Layout/index';

import { UserAPI } from '@/api/user';
import couple from '@/assets/survey/couple.png';
import details from '@/assets/survey/fancyRoom.png';
import fix from '@/assets/survey/fix.png';
import building from '@/assets/survey/redhouse.png';
import { useMobile, useMobileMini } from '@/hooks/useAdaptives';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();

mic.continuous = true;
mic.interimResults = true;
mic.lang = "en-US";

export const Quiz = ({ isOpen, onClose = () => { } }) => {
    const [curr_section, setCurr_section] = useState(0);
    const [curr_question, setCurr_question] = useState(0);
    const [in_ui_questions, setIn_ui_questions] = useState(false);
    const [in_section_intro, setIn_section_intro] = useState(true);
    const [base_quiz, setBaseQuiz] = useState(null);
    const [recording, setRecording] = useState("");
    const [waiting, setWaiting] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioStream, setAudioStream] = useState(null);
    const [isSpeaking, setIsSpeaking] = useState(true);

    // const navigate = useNavigate();
    const isMobile = useMobile();
    const isMobileMini = useMobileMini();

    const curr_chapter = [
        {
            'background': styles.bg_1,
            'highlights': '#F9CCB9',
            'step': '#F9CCB9',
            'starter_question': 'Tell us a bit about you and your situation',
            'intro': "Let's learn more about who will be living in your future home with you",
            'intro_img': couple,
            'intro_img_style': { height: '23rem', marginTop: '0.5rem' }
        },
        {
            'background': styles.bg_2,
            'highlights': '#FCB0AA',
            'step': '#8C1111',
            'starter_question': 'What factors are important to you in a location?',
            'intro': "Let's find out what makes a great location for you",
            'intro_img': building,
            'intro_img_style': { width: '25rem', marginTop: '10rem' }
        },
        {
            'background': styles.bg_3,
            'highlights': '#F6EDDE',
            'step': '#A4652A',
            'starter_question': 'What features & attributes do you prioritize?',
            'intro': "Let's discover what you value most in a property",
            'intro_img': details,
            'intro_img_style': { height: '25rem' }
        },
        {
            'background': styles.bg_4,
            'highlights': '#DFEEF2',
            'step': '#38779A',
            'starter_question': 'What improvements are you willing to do and avoid?',
            'intro': "Let's see how open you are to considering a home that needs work",
            'intro_img': fix,
            'intro_img_style': { width: '25rem' }
        },
    ]

    useEffect(() => {
        const fetch = async () => {
            const resp = await UserAPI.getBaseQuiz();
            setBaseQuiz(resp);
        }
        fetch();
    }, [])

    useEffect(() => {
        if (base_quiz) {
            if (curr_question === base_quiz[curr_section]?.questions.length) {
                setCurr_question(0);
                if (curr_section + 1 === base_quiz.length) {
                    setCurr_section(0);
                    setIn_ui_questions(false);
                    onClose();
                    track('Completed V2 Intake Quiz');
                    location.reload();
                } else {
                    setCurr_section(curr_section + 1);
                }
                setIn_ui_questions(false);
                setIn_section_intro(true);
            }
        }
    }, [curr_question])

    useEffect(() => {
        if (base_quiz) {
            setIn_section_intro(true);
            if (curr_section === base_quiz.length) {
                setCurr_question(0);
                setCurr_section(0);
                setIn_ui_questions(false);
                onClose();
                // track('Completed V2 Intake Quiz');
                location.reload();
                // navigate('/summary');
            }
        }
    }, [curr_section])

    // Mic Recording
    const [isListening, setIsListening] = useState(false);

    useEffect(() => {
        if (isListening) {
            startRecording();
        } else {
            stopRecording();
            setMediaRecorder(null);
        }
    }, [isListening]);

    const [blob, setBlob] = useState();
    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setAudioStream(stream);
            const recorder = new MediaRecorder(stream);
            setMediaRecorder(recorder);

            recorder.start();
            mic.start();
            mic.onend = () => {

            };

            recorder.ondataavailable = (event) => {
                setBlob(event.data);
            };

            mic.onresult = (event) => {
                const transcript = Array.from(event.results)
                    .map(result => result[0].transcript)
                    .join("");
                setRecording(transcript);
            };

            mic.onerror = (event) => {
                console.log(event.error);
            };
        } catch (err) {
            console.error("Failed to start recording", err);
        }
    };

    const stopRecording = () => {
        if (mediaRecorder) {
            mediaRecorder.stop();
        }
        if (audioStream) {
            audioStream.getTracks().forEach(track => track.stop());
        }
        mic.stop();
    };

    const closeCard = () => {
        setIsListening(false);
        onClose();
    }

    const enterUI = async () => {
        if (recording !== "") {
            setWaiting(true);
            if (isListening) {
                setIsListening(false);
            }
            // console.log(recording);
            const body = {
                "section_index": curr_section,
                "section_response": recording,
            }
            const resp = await UserAPI.processSection(body);
            setWaiting(false);
            setBaseQuiz(prevData => {
                return prevData.map(item => {
                    if (item.id === curr_section + 1) {
                        return {
                            ...item,
                            questions: resp["not_satisfied_questions"]
                        };
                    }
                    return item;
                });
            });

            if (resp["not_satisfied_questions"].length === 0) {
                setCurr_section(curr_section + 1);
            } else {
                setIn_ui_questions(true);
            }
            setRecording("");
        }
    }

    const onNext = async () => {
        if (response) {
            await handleResponse();
            setResponse(null);
            setCurr_question(curr_question + 1);
        }
    }

    const [response, setResponse] = useState(null);
    const [rank, setRank] = useState([]);

    useEffect(() => {
        if (base_quiz && base_quiz[curr_section]?.questions[curr_question]?.input_type === "RANK") {
            let temp = []
            base_quiz[curr_section]?.questions[curr_question]?.options.map((options, key) => {
                temp.push({ name: options, id: key + 1 })
            })

            setResponse(temp);
            setRank(temp);
        } else if (base_quiz && base_quiz[curr_section]?.questions[curr_question]?.input_type === "SLIDER") {
            setResponse(base_quiz[curr_section]?.questions[curr_question]?.min_max[0])
        } else {
            setResponse("");
        }

    }, [curr_question, in_ui_questions])

    const handleResponse = async () => {
        const response1 = await UserAPI.getSectionResponse();
        const section_id = response1.find(data => data.section === (curr_section + 1));

        const body = {
            "question_id": base_quiz[curr_section]?.questions[curr_question]?.id,
            "section_response_id": section_id.id,
            "response": response,
        }

        await UserAPI.questionResponse(body)
        // console.log(response2)
    }

    const handleCheckboxChange = (option) => {
        setResponse((prevResponse) => {
            if (prevResponse === null) {
                // Initialize the response as an array with the first selected option
                return [option];
            }

            // Toggle the option in the array
            return prevResponse.includes(option)
                ? prevResponse.filter((selectedOption) => selectedOption !== option)
                : [...prevResponse, option];
        });
    };

    const handleSlideChange = (event) => {
        setResponse(event.target.value);
    }

    // DND Scripting for touch and mouse
    const isTouchDevice = () => {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    };

    const moveItem = (draggedId, targetId) => {
        const draggedIndex = rank.findIndex(item => item.id === draggedId);
        const targetIndex = rank.findIndex(item => item.id === targetId);
        console.log(`Dragged ID: ${draggedId}, Target ID: ${targetId}`);
        if (draggedIndex === -1 || targetIndex === -1) return;

        const updatedItems = [...rank];
        const [draggedItem] = updatedItems.splice(draggedIndex, 1); // Remove dragged item
        updatedItems.splice(targetIndex, 0, draggedItem); // Insert dragged item at target location

        setRank(updatedItems);
        setResponse(updatedItems);
    };

    const steps = 4;
    const status_bar = <div className={styles.status_bar + " " + ((isMobile || isMobileMini) ? 'mt-4' : '')}>
        {Array.from({ length: steps }).map((_, index) => (
            <div
                key={index}
                className={styles.step}
                style={curr_section === index ? { background: curr_chapter[curr_section].step } :
                    (curr_section === 0 ? {} : { background: curr_chapter[curr_section].highlights })}
            ></div>
        ))}
    </div>

    return (
        <Modal
            title={status_bar}
            isOpen={isOpen}
            onClose={closeCard}
            panelClassName={curr_chapter[curr_section]['background']}
        >
            <Col className={styles.contents_wrapper}>
                {
                    in_ui_questions ?
                        (
                            <>
                                <h2 className={/*styles.description + */" text-center " + (curr_section !== 0 ? styles.title : "")}>
                                    {base_quiz && base_quiz[curr_section]?.questions[curr_question]?.prompt}
                                </h2>
                                <div
                                    className={"d-flex justify-content-center mt-5"}
                                    style={{ minHeight: ((isMobile || isMobileMini) ? '20rem' : '15rem') }}
                                >
                                    {base_quiz && base_quiz[curr_section]?.questions[curr_question]?.input_type === "TEXT" && (
                                        <Input
                                            className={styles.input}
                                            type="text"
                                            placeholder="Type here"
                                            name="response"
                                            defaultValue={""}
                                            id="inputText"
                                            onChange={setResponse}
                                        />
                                    )}
                                    {base_quiz && base_quiz[curr_section]?.questions[curr_question]?.input_type === "RADIO" && (
                                        <Col style={{ justifyContent: "start" }}>
                                            {base_quiz && base_quiz[curr_section]?.questions[curr_question]?.options.map((option, key) => (
                                                <div
                                                    className={styles.cue + " px-2 py-1 my-2"}
                                                    style={{ background: curr_chapter[curr_section].highlights }}
                                                    key={key}
                                                >
                                                    <div
                                                        className={"form-check"}
                                                        onClick={() => { setResponse(option) }}
                                                    >
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name={curr_question}
                                                            id={option} value={response}
                                                            checked={response?.includes(option)}
                                                        />
                                                        <label className="form-check-label">
                                                            {option}
                                                        </label>
                                                    </div>
                                                </div>
                                            ))}
                                        </Col>
                                    )}
                                    {base_quiz && base_quiz[curr_section]?.questions[curr_question]?.input_type === "RANK" && (
                                        <Col style={{ alignItems: 'center', justifyContent: 'start' }}>
                                            <h4 className='mb-3'>
                                                Drag your preferences in order
                                            </h4>
                                            <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
                                                <div
                                                    style={{
                                                        maxHeight: ((isMobile || isMobileMini) ? '25rem' : '20rem'),
                                                        overflowY: (rank.length > 9 ? 'scroll' : 'auto'),
                                                        WebkitOverflowScrolling: 'touch',
                                                        padding: '10px'
                                                    }}

                                                    className={styles.custom_scrollbar}
                                                >
                                                    <DroppableArea
                                                        items={rank}
                                                        moveItem={moveItem}
                                                        rank={rank}
                                                        setRank={setRank}
                                                        itemClass={styles.cue + " px-2 py-1 my-2"}
                                                        itemStyle={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            marginBottom: '8px',
                                                            background: curr_chapter[curr_section].highlights,
                                                            textAlign: 'center',
                                                        }}
                                                    />
                                                </div>
                                            </DndProvider>
                                        </Col>
                                    )}
                                    {base_quiz && base_quiz[curr_section]?.questions[curr_question]?.input_type === "SLIDER" && (
                                        <Box
                                            sx={{
                                                width: {
                                                    xs: '250px', // 80% width for extra-small screens (mobile)
                                                    sm: '300px', // 70% width for small screens (tablets)
                                                    md: '600px', // Fixed width for medium and larger screens (laptops and desktops)
                                                },
                                                maxWidth: '100%', // Ensure it doesn't exceed 100% width
                                                margin: '0 auto', // Center the box
                                            }}
                                        >
                                            <Slider
                                                defaultValue={base_quiz && parseInt(base_quiz[curr_section]?.questions[curr_question]?.min_max[0])}
                                                sx={{
                                                    color: curr_chapter[curr_section].step,
                                                    height: {
                                                        xs: 8, // Reduce height for mobile screens
                                                        md: 12, // Default height for larger screens
                                                    },
                                                    '& .MuiSlider-track': {
                                                        border: 'none',
                                                    },
                                                    '& .MuiSlider-thumb': {
                                                        height: {
                                                            xs: 24, // Smaller thumb for mobile screens
                                                            md: 29, // Default size for larger screens
                                                        },
                                                        width: {
                                                            xs: 24, // Smaller thumb width for mobile screens
                                                            md: 29, // Default size for larger screens
                                                        },
                                                        backgroundColor: curr_chapter[curr_section].highlights,
                                                        border: '7px solid ' + curr_chapter[curr_section].step,
                                                        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                                                            boxShadow: 'inherit',
                                                        },
                                                        '&::before': {
                                                            display: 'none',
                                                        },
                                                    },
                                                    '& .MuiSlider-rail': {
                                                        opacity: 1.0,
                                                        backgroundColor: curr_chapter[curr_section].highlights,
                                                    },
                                                }}
                                                aria-label="slider"
                                                valueLabelDisplay="off"
                                                step={1}
                                                marks
                                                min={base_quiz && parseInt(base_quiz[curr_section]?.questions[curr_question]?.min_max[0])}
                                                max={base_quiz && parseInt(base_quiz[curr_section]?.questions[curr_question]?.min_max[1])}
                                                onChange={handleSlideChange}
                                            />
                                        </Box>
                                    )}
                                    {base_quiz && base_quiz[curr_section]?.questions[curr_question]?.input_type === "CHECKBOX" && (
                                        <Col style={{ justifyContent: 'start' }}>
                                            {base_quiz && base_quiz[curr_section]?.questions[curr_question]?.options.map((option, key) => (
                                                <div
                                                    className={styles.cue + " px-2 py-1 my-2"}
                                                    style={{ background: curr_chapter[curr_section].highlights }}
                                                    key={key}
                                                    onClick={() => handleCheckboxChange(option)}
                                                >
                                                    <div className="form-check">
                                                        <input className="form-check-input"
                                                            type="checkbox" name={key}
                                                            id={key} value={option}
                                                            checked={response?.includes(option)}
                                                        />
                                                        <label className="form-check-label">
                                                            {option}
                                                        </label>
                                                    </div>
                                                </div>
                                            ))}
                                        </Col>
                                    )}
                                </div>
                                <Button onClick={() => { onNext() }} aria-label="in_quiz" className={styles.continueBtn + " mt-3"}>Next</Button>
                            </>
                        )
                        :
                        (
                            in_section_intro ?
                                (
                                    <>
                                        <Img src={curr_chapter[curr_section]["intro_img"]} style={curr_chapter[curr_section]["intro_img_style"]} />
                                        <h2 className={"fw-bold text-center mt-4"}>
                                            {base_quiz && base_quiz[curr_section]["title"]}
                                        </h2>
                                        <p className='text-center fs-5 my-3'>
                                            {curr_chapter[curr_section]["intro"]}
                                        </p>
                                        <Button
                                            onClick={() => { setIn_section_intro(false) }}
                                            className={styles.continueBtn + " mt-1"}
                                            style={{ width: '15rem' }}
                                        >
                                            Get Started
                                        </Button>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <h2 className={"fw-bold text-center " + (isListening ? "" : "mb-5 ") + (curr_section !== 0 ? styles.title : "")}>
                                            {curr_chapter[curr_section]["starter_question"]}
                                        </h2>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            {
                                                isSpeaking ? (
                                                    <>
                                                        <div>
                                                            {isListening && mediaRecorder && (
                                                                <LiveAudioVisualizer
                                                                    mediaRecorder={mediaRecorder}
                                                                    width={200}
                                                                    height={75}
                                                                    barColor={curr_chapter[curr_section].highlights}
                                                                />
                                                            )}
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className={isListening ? "btn btn-danger my-4" : "btn btn-primary my-4"}
                                                            onClick={() => setIsListening((prevState) => !prevState)}
                                                        >
                                                            {isListening ? "Stop Recording" : "Record"}
                                                        </button>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Input
                                                            className={styles.input + " mt-3 mb-4"}
                                                            type="text"
                                                            placeholder="Type here"
                                                            name="recording"
                                                            defaultValue={recording || ""}
                                                            id="inputText"
                                                            onChange={setRecording}
                                                        />
                                                    </>
                                                )
                                            }
                                        </div>
                                        <p onClick={() => { setIsSpeaking(!isSpeaking) }} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                            {isSpeaking ? "I'd rather type" : "I'd rather speak"}
                                        </p>
                                        <ul>
                                            {base_quiz && base_quiz[curr_section]?.cues.map((cue, index) => (
                                                <li key={index}
                                                    className={styles.cue + " px-2 py-1 my-3"}
                                                    style={{ background: curr_chapter[curr_section].highlights }}
                                                >
                                                    <p className="text-center">{cue}</p>
                                                </li>
                                            ))}
                                        </ul>
                                        <Button
                                            onClick={() => { if (!waiting) enterUI() }}
                                            aria-label="in_quiz"
                                            className={styles.continueBtn + ' mt-4'}
                                            style={{ width: '15rem' }}
                                        >
                                            {waiting ? "Thinking..." : "Next"}
                                        </Button>
                                    </>
                                )
                        )
                }
            </Col>
        </Modal>
    )
}

Quiz.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}