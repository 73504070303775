import {useEffect} from "react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {TouchBackend} from "react-dnd-touch-backend";
import {BrowserRouter, useNavigate} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Router } from './router';



const App = () => {
    const isTouchDevice = () => "ontouchstart" in window;

    const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;



    return (
        <DndProvider backend={backendForDND} options={{ enableMouseEvents: true }}>
            <div className="App">
                <BrowserRouter>
                    <Router />
                </BrowserRouter>
                <ToastContainer />
            </div>
        </DndProvider>
    );
};

export default App;
