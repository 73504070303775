import React, {useState, useEffect, useRef} from 'react';
import {Link, useLocation} from 'react-router-dom';

import styles from './index.module.scss';

import {ReportAPI} from '@/api/report';
import {UserAPI} from '@/api/user';
import fillerHouse from '@/assets/fillerHouse.png';
import angleArrowDown from '@/assets/icons/angleArrowDown.svg';
import angleArrowUp from '@/assets/icons/angleArrowUp.svg';
import {Contents, Col, Layout, Row, Img, Input, MobileLayout, Layout as MainLayout} from '@/components/Layout';
import MobileMenu from "@/components/mobileMenu/mobileMenu";
import MobileTopBar from "@/components/mobileTopBar/mobileTopBar";
import {Survey} from '@/components/v1Survey';
import {useDesktop, useDesktopMini} from "@/hooks/useAdaptives";
import {getReportAddresses} from '@/utils';
import * as mainContents from '@/views/Comparison/main';


const DOCS_PRIVACY_URL = process.env.REACT_APP_DOCS_PRIVACY_URL;
const DOCS_TERMS_URL = process.env.REACT_APP_DOCS_TERMS_URL;

const Tabs = {
    yourInsights: {
        key: 'your-insights',
        name: 'Your Insights',
    },
    problemAreas: {
        key: 'problem-areas',
        name: 'Problem Areas',
    },
    // byRoom: {
    //     key: 'by-room',
    //     name: 'By Room',
    // },
};

const tabsComponents = {
    [Tabs.yourInsights.key]: mainContents,
    [Tabs.problemAreas.key]: {
        Body: props => <div>NOT IMPLEMENTED</div>,
        Controls: props => <></>,
    },
    // [Tabs.byRoom.key]: {
    //     Body: props => <div>NOT IMPLEMENTED</div>,
    //     Controls: props => <></>,
    // },
};

const defaultTab = Tabs.yourInsights;

export const Comparison = () => {
    const housesRef = useRef(null);
    const {hash} = useLocation();
    const [reports, setReports] = useState([]);
    const [data, setData] = useState({});
    const [allowComp, setAllowComp] = useState(false);
    const isDesktop = useDesktop();
    const isDesktopMini = useDesktopMini();


    useEffect(() => {
        ReportAPI.getReports().then(resp => {
            let temp = resp.filter(datum => datum.details.mlsid)
            setReports(temp);

            const copy = temp.reduce((acc, item) => {
                acc[item.id] = item;
                return acc;
            }, {});
            setData(copy);
        })
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        UserAPI.getUserPreferences()
            .then((data) => {
                if (data.length === 8) {
                    setAllowComp(true);
                }
            })
    }, [])

    const [comparisons, setComparisons] = useState([]);
    const [main, setMain] = useState(false)
    const [observed, setObserved] = useState([]);

    useEffect(() => {
        if (comparisons.length === 2) {
            if (!main) setMain(true);
            setDropdown(false);
            setObserved(comparisons);
        }
    }, [comparisons.length, main]);

    const [currentTab, setCurrentTab] = useState(defaultTab.key);
    const [tabState, setTabState] = useState({});

    const tabComponents = tabsComponents[currentTab] || tabsComponents[defaultTab.key];
    const TabContents = tabComponents.Body;
    const TabControls = tabComponents.Controls;

    useEffect(() => {
        setCurrentTab(hash.replace('#', '') || defaultTab.key);
        setTabState({});
    }, [hash]);

    const [dropdown, setDropdown] = useState(false);

    const isChecked = (value) => {
        return comparisons.includes(value);
    }

    const handleChange = (reportId) => {
        const value = parseInt(reportId)
        if (isChecked(value)) {
            const updatedChoices = comparisons.filter(comparison => comparison !== value)
            setComparisons(updatedChoices);
        } else if (comparisons.length !== 2) {
            setComparisons([...comparisons, value]);
        }
    }

    const Layout = !isDesktop && !isDesktopMini ? MobileLayout : MainLayout;


    return (
        <Layout mainAddClass={!isDesktop && !isDesktopMini ? styles.mobile_main : ''}>
            {!isDesktop && !isDesktopMini ? <MobileTopBar title={''} style={{background: 'inherit'}}/> : null}
            <Contents className={'d-flex flex-column flex-grow-1 pb-4 mx-auto'}>
                {allowComp ?
                    <>
                        <h1 className={styles.title}>Compare Properties</h1>
                        {comparisons.length === 2 ?
                            <h2 className={styles.subtitle}>
                                <b>{data[observed[0]]?.name === "Sample Report" ? "Sample Report" : getReportAddresses(data[observed[0]]).address2} </b>
                                and
                                <b> {data[observed[1]]?.name === "Sample Report" ? "Sample Report" : getReportAddresses(data[observed[1]]).address2}</b>
                            </h2> : ""}


                        {comparisons.length === 0 ? <h3>No Homes Selected.</h3> : comparisons.length === 1 ?
                            <h3>Select two homes to compare.</h3> : ""}
                        <Row className={'w-100 align-items-start justify-content-center flex-grow-1 my-4'}>
                            <div className={`${styles.dropdownHeaderAlign} position-relative w-100`}>
                                <div className={`${styles.dropdownHeader} bg-white text-dark py-2 px-3 rounded-pill cursor-pointer`}
                                     onClick={e => (e.preventDefault(), setDropdown(!dropdown))}>
                                    <Row>
                                        <p style={{fontSize: '1.2rem', lineHeight: '.7'}}>{`Compare between (${comparisons.length}/2)`}</p>
                                        <Img src={!dropdown ? angleArrowDown : angleArrowUp}/>
                                    </Row>
                                </div>
                                {dropdown && (
                                    <div className={`${styles.dropdown} w-100 bg-white text-dark rounded-3 position-absolute top-100 start-0 mt-2`}>
                                        <p className={'mt-1 mb-2'}>Please, choose up to 2 options*</p>
                                            {reports.map((report, index) => (
                                                <Row key={index} className={'my-2 py-2 px-2 px-sm-4 flex-nowrap'}
                                                     onClick={() => handleChange(report.id)}>
                                                    <Input className={'me-2'}
                                                        type="checkbox" value={report.id}
                                                        checked={isChecked(report.id)}
                                                        onChange={() => handleChange(report.id)}/>
                                                    <Img className={'me-1'} src={report.details.imgSrc || fillerHouse}
                                                         preloadSrc={fillerHouse}/>
                                                    <Col className={'w-50 text-start'}>
                                                        <p>{report?.name === "Sample Report" ? "Sample Report" : getReportAddresses(report).address2}</p>
                                                        <p>{report?.name === "Sample Report" ? "" : getReportAddresses(report).address1}</p>
                                                    </Col>
                                                </Row>
                                            ))}
                                    </div>
                                )}
                            </div>

                            <TabControls propertyId1={observed[0]} propertyId2={observed[1]} report1={data[observed[0]]}
                                         report2={data[observed[1]]} setState={setTabState} state={tabState}
                                         comparison={comparisons}/>
                        </Row>

                        {comparisons.length === 2 &&
                            <TabContents propertyId1={observed[0]} propertyId2={observed[1]} report1={data[observed[0]]}
                                         report2={data[observed[1]]} state={tabState} comparison={comparisons}/>}
                    </>
                    :
                    <Col className={'mt-4 mb-3 align-items-center justify-content-start flex-grow-1'}>
                        <h1 className={styles.title}>Please take our Buyer Preference Quiz to get comparisons based on
                            your preferences.</h1>
                        <Survey title={"Take our Buyer Preference Quiz"} style={{marginTop: '50px'}}/>
                    </Col>
                }
                <Row className={`${styles.footer} mt-auto py-3 w-100 gap-1 justify-content-center border-top py-2`}>
                    <span>©2024 Homescore&nbsp; </span> | <Link to={DOCS_TERMS_URL}>&nbsp; Terms of
                    Service&nbsp; </Link> |
                    <Link to={DOCS_PRIVACY_URL}>&nbsp; Privacy Policy</Link>
                </Row>
            </Contents>
        </Layout>
    );
};