import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import styles from './index.module.scss';

import { Authentication } from '@/components/Authentication';
import { Row } from "@/components/Layout";
import { selectLoading } from "@/store/user/selectors";
import { loginUser } from '@/store/user/slice';

const { REACT_APP_FORGOT_PASSWORD_URL } = process.env;

export const Login = () => {
    const dispatch = useDispatch();
    const loading = useSelector(selectLoading);


    const login = (name, email, password) => {
        dispatch(loginUser({ username: email, email, password }));
    };

    return (
        <Authentication subtitle='Welcome back!' buttonType='Log In' onClick={login} loading={loading} seeGoogle={true}>
            <Row className={`${styles.links_wrapper} flex-nowrap`}>
                <Link to={'/reset'} className={'text-center text-decoration-underline'}>
                    Forgot password?
                </Link>
                <Link to={'/register'} className={'text-center text-decoration-underline'}>
                    Don&apos;t have an account?
                </Link>
            </Row>
        </Authentication>
    );
};
