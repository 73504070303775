import PropTypes from "prop-types";
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const LocationHistoryContext = createContext();

export const LocationHistoryProvider = ({ children }) => {
    const [locationHistory, setLocationHistory] = useState([]); // Храним историю роутов
    const location = useLocation();

    useEffect(() => {
        setLocationHistory((prevHistory) => {
            const newHistory = [...prevHistory, location];
            return newHistory.slice(-3);
        });
    }, [location]);

    return (
        <LocationHistoryContext.Provider value={locationHistory}>
            {children}
        </LocationHistoryContext.Provider>
    );
};

export const useLocationHistory = () => useContext(LocationHistoryContext);

LocationHistoryProvider.propTypes = {
    children: PropTypes.node,
};


