import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import styles from './Intro.module.scss';
import { Button, Col, Img, Modal } from '../Layout';

import welcome1 from '@/assets/survey/welcome1.png';
import welcome2 from '@/assets/survey/welcome2.png';

export const Intro = ({ isOpen, onClose = () => { }, onNext = () => { } }) => {
    const [index, setIndex] = useState(0);

    const next = () => {
        if (index + 1 === intro.length) {
            onClose();
            onNext();
            setIndex(0);
        } else {
            setIndex(index + 1);
        }
    }

    const intro = [
        {
            'title': "Let's Discover What You're Looking For",
            'image': welcome1,
            'image_style': { width: '20rem' },
            'background_color': styles.intro1_bg,
            'description': "By learning your preferences, we can showcase the property insights that matter most to you.",
        },
        {
            'title': "It takes Less Than 6 Minutes",
            'image': welcome2,
            'image_style': { height: '16rem' },
            'background_color': styles.intro2_bg,
            'description': "There are no right or wrong answers. It's all about you. Just speak naturally and don't overthink it.",
        },
    ]

    return (
        <Modal title={""} isOpen={isOpen} onClose={onClose} panelClassName={intro[index].background_color}>
            <Col style={{ alignItems: 'center', textAlign: 'center' }}>
                <Img src={intro[index].image} style={intro[index].image_style} />
                <h1 className={"my-3"} style={{ color: 'white' }}>
                    {intro[index] && intro[index].title}
                </h1>
                <p style={{ color: 'white' }}>
                    {intro[index] && intro[index].description}
                </p>
                <Button className={"mt-3"} style={{ width: '15rem' }} onClick={() => { next() }}>
                    Next
                </Button>
            </Col>
        </Modal>
    )
}

Intro.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onNext: PropTypes.func,
}