import { track } from '@vercel/analytics/react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import GoogleButton from "react-google-button";

import styles from './index.module.scss';

import logo from '@/assets/headerLogo.svg';
import signIn from '@/assets/icons/sign_in.png';
import signUp from '@/assets/icons/sign_up.png'
import intro from '@/assets/openingImage.svg';
import Card, { CardType } from '@/components/Card';
import { Row, Col, Button, Img, Input } from '@/components/Layout';
import { ColorAccent } from '@/constants';


const onGoogleLoginSuccess = () => {
    const GOOGLE_AUTH_URL = 'https://accounts.google.com/o/oauth2/v2/auth';
    const REDIRECT_URI = 'accounts/google/login/callback/';

    const scope = [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile'
    ].join(' ');

    const params = {
        response_type: 'code',
        client_id: process.env.REACT_APP_GOOGLE_AUTH_KEY,
        redirect_uri: `${process.env.REACT_APP_API_URL}/${REDIRECT_URI}`,
        prompt: 'select_account',
        access_type: 'online',
        scope
    };

    if (location.pathname === "/register") {
        localStorage.setItem("register", "register")
    }

    const urlParams = new URLSearchParams(params).toString();
    window.location = `${GOOGLE_AUTH_URL}?${urlParams}`;
    track("Google Sign In")
};

const GoogleLoginButton = () => {
    return <Button
        colorAccent={ColorAccent.black}
        onClick={onGoogleLoginSuccess}
        className={`${styles.googleButton} w-100 py-3 mt-3`}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="32px" height="32px">
            <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z" />
            <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z" />
            <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z" />
            <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z" />
        </svg>
        Sign in with Google
    </Button>
}

export const Authentication = ({
    children,
    subtitle = "",
    seeEmail = true,
    seePassword = true,
    seeGoogle = false,
    buttonType = "",
    onClick = () => {
    },
    loading = false
}) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onSubmit = async e => {
        e.preventDefault();
        onClick(name, email, password);
    };

    return (
        <Row className={`${styles.authentication_block} row p-md-2 w-100 my-0 mx-auto justify-content-center`}>
            <Col className={`${styles.intro_img} d-none d-md-flex col-md-6 align-items-end align-items-md-center`}>
                <Img src={intro} />
            </Col>
            <Col className={'col-12 col-md-6 align-items-center p-0'}>
                <Card className={styles.formCard} type={CardType.autoBox}>
                    <Card.Header className={styles.header}>
                        <Col className={'align-items-center'}>
                            <Img style={{ height: '3.5rem' }} src={logo}></Img>
                            <h1 className={styles.subtitle}>{subtitle}</h1>
                        </Col>
                    </Card.Header>
                    <Card.Body>
                        {buttonType !== 'Change Password' ? <form onSubmit={onSubmit}>
                            {
                                buttonType === 'Sign Up' && (
                                    <Input
                                        className={`${styles.input} w-100 border rounded-pill`}
                                        type="text"
                                        placeholder="Name"
                                        defaultValue={name}
                                        onChange={setName}
                                        name="name"
                                        id="inputName"
                                        pattern="[^\s]+"
                                        required
                                    />
                                )
                            }
                            {seeEmail && (
                                <Input
                                    className={`${styles.input} w-100 border rounded-pill`}
                                    type="email"
                                    placeholder="E-mail"
                                    defaultValue={email}
                                    onChange={setEmail}
                                    name="email"
                                    id="inputEmail"
                                    required
                                />
                            )}
                            {seePassword && (
                                <Input
                                    className={`${styles.input} w-100 border rounded-pill`}
                                    type="password"
                                    placeholder="Password"
                                    defaultValue={password}
                                    onChange={setPassword}
                                    id="inputPassword"
                                    required
                                />
                            )}
                            {(seeEmail || seePassword) && (
                                <>
                                    <Button colorAccent={ColorAccent.black}
                                        className={'w-100 py-3 mt-3'}
                                        type="submit"
                                        style={{ opacity: loading ? '.7' : '1' }}
                                        disabled={loading}>
                                        {
                                            loading ? <div className="spinner-border text-white" role="status"></div>
                                                : <>
                                                    {['Sign Up', 'Log In'].includes(buttonType)
                                                        ? <img
                                                            src={buttonType === 'Sign Up' ? signUp : buttonType === 'Log In' ? signIn : ''}
                                                            alt=""
                                                            style={{ width: '32px', height: '32px', marginLeft: '5px' }} />
                                                        : null}
                                                    {buttonType}
                                                </>
                                        }
                                    </Button>
                                    {seeGoogle && (
                                        <div style={{ display: buttonType === 'Sign Up' || buttonType === 'Log In' ? 'block' : 'none' }}>
                                            <GoogleLoginButton />
                                        </div>
                                    )}
                                </>
                            )}
                        </form> : null}
                        {children}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

Authentication.propTypes = {
    children: PropTypes.node,
    subtitle: PropTypes.any.isRequired,
    buttonType: PropTypes.string,
    seeEmail: PropTypes.bool,
    seePassword: PropTypes.bool,
    seeGoogle: PropTypes.bool,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
}