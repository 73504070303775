import PropTypes from "prop-types";
import React, {useState} from "react";


import common_styles from './onboarding.module.scss';
import styles from './propertyQuality.module.scss';

import appliances from "@/assets/onboarding/propertyQuality/appliances (2).png";
import light from "@/assets/onboarding/propertyQuality/light.png";
import man from "@/assets/onboarding/propertyQuality/man.png";
import quality from "@/assets/onboarding/propertyQuality/quality.png";
import size from "@/assets/onboarding/propertyQuality/size (2).png";
import {Button} from "@/components/Layout";
import {ColorAccent} from "@/constants";
import {useWindowHeight} from "@/hooks/useWindowHeight";

const PropertyQuality = ({
                             onClose = () => {
                             },
                             onNext = () => {
                             }
                         }) => {
    const windowHeight = useWindowHeight();

    return(
        <div className={styles.main_wrapper}>
            <div className={common_styles.content_wrapper}>

                <img src={size} alt="" className={styles.size}/>
                <img src={quality} alt="" className={styles.quality}/>
                <img src={appliances} alt="" className={styles.appliances}/>
                <img src={light} alt="" className={styles.light}/>
                <img src={man} alt="" className={styles.man}/>

                <div className={`${common_styles.info_card} ${windowHeight < 730 ? common_styles.small : ''}`}>
                    <div className={common_styles.title}>Assess property quality <br/> pre-inspection</div>
                    <div className={common_styles.description}>Use your photos to gain peace of mind on every aspect of the homes you’re considering.</div>
                    <Button colorAccent={ColorAccent.black} className={'w-100 py-3 mb-3'} onClick={onNext}>
                        Next
                    </Button>
                    <div className={common_styles.skip} onClick={onClose}>Skip for now</div>
                </div>
            </div>
        </div>
    )
}

PropertyQuality.propTypes = {
    onClose: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
}
export default PropertyQuality;