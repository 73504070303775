import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { StripeAPI} from "@/api/stripe";

const initialState = {
    //for example user subscriptions
    stripeInfo: {},

}

export const stripeSlice = createSlice({
    name: 'stripe',
    initialState,
    reducers: {
        getStripeInfo: (state, action) => {
            state.stripeInfo = action.payload
        },
    }
})

export const {getStripeInfo} = stripeSlice.actions;

export const stripeReducer = stripeSlice.reducer;