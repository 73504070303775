import { useSpring, animated } from "@react-spring/web";
import { useDrag } from '@use-gesture/react'
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";

import styles from './DraggablePropertyCard.module.scss';

import { KanbanState } from "@/api/report";
import awaiting_details from "@/assets/kanbanCard/awaiting_details.png";
import considering from "@/assets/kanbanCard/considering.png";
import shortlisted from "@/assets/kanbanCard/shortlisted.png";
import viewed from "@/assets/kanbanCard/viewed.png";
import KanbanCard from "@/components/kanbanCard/kanbanCard";
import { dispatch } from "@/store";
import { moveCardOnKanban, removeHome, removeReport } from "@/store/reports/reportsSlice";

const DraggablePropertyCard = ({ index, report }) => {
    const [initPos, setIntiPos] = useState(0);
    const [direction, setDirection] = useState(null);
    const [shouldBeMoved, setShouldBeMoved] = useState(null);

    const ref = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [springProps, api] = useSpring(() => ({ x: 0 }));

    // dragging handler
    const bind = useDrag((state) => {
        // Determine the offset and update the animation state
        const { down, movement: [mx, my], direction: [dx, dy], offset: [ox, oy] } = state;

        if (down) {
            api.start({ x: mx });
            setIsDragging(true);
            // setDirection(mx < 0 ? 'left' : 'right');
        } else {
            //to prevent on link click
            setTimeout(() => setIsDragging(false), 0);
            //if initial posotion is 0
            const containerWidth = ref?.current.getBoundingClientRect().width; // Измените на нужный контейнер

            const newX = (40 * containerWidth) / 100;

            if (ref?.current.getAttribute('data-name') === KanbanState.CONSIDERING && mx < 0) {
                api.start({ x: 0 });
                setIntiPos(0);
                setDirection('null');
            } else if (ref?.current.getAttribute('data-name') === KanbanState.SHORTLISTED && mx > 0) {
                api.start({ x: 0 });
                setIntiPos(0);
                setDirection('null');
            } else if (!initPos && mx > 0 && mx < 200) {
                api.start({ x: mx < 0 ? -newX : newX });
                setDirection('right');
                setIntiPos(mx < 0 ? -newX : newX);
            } else if (!initPos && mx > 0 && mx > 200) {
                api.start({ x: 400 });
                setDirection('right');
                setIntiPos(0);
                setShouldBeMoved('right');
            } else if (!initPos && mx < 0 && mx < -200) {
                api.start({ x: -400 });
                setDirection('left');
                setIntiPos(0);
                setShouldBeMoved('left');
            } else if (!initPos && mx < 0 && mx > -200) {
                api.start({ x: mx < 0 ? -newX : newX });
                setDirection('left');
                setIntiPos(mx < 0 ? -newX : newX);
            } else if (initPos < 0 && mx > 0) {
                api.start({ x: 0 });
                setIntiPos(0);
                setDirection('null');
            } else if (initPos < 0 && mx < 0) {
                api.start({ x: -400 });
                setDirection('left');
                setIntiPos(0);
                setShouldBeMoved('left');
            } else if (initPos > 0 && mx < 0) {
                api.start({ x: 0 });
                setIntiPos(0);
                setDirection('null');
            } else if (initPos > 0 && mx > 0) {
                api.start({ x: 400 });
                setIntiPos(0)
                setDirection('right');
                setShouldBeMoved('right');
            }
        }
    }, {
        axis: 'x'
    });


    //to prevent go link
    const handleClick = useCallback((e) => {
        if (isDragging) {
            e?.preventDefault(); // if the element was dragging we prevent the navigation by link (when click the card)
        }
    }, [isDragging]);

    const movingItemTabInfoObj = {
        [KanbanState.CONSIDERING]: {
            ['right']: [{ newStatus: KanbanState.VIEWED, position: 'left', reportId: report.id }],
            ['left']: [{}],
        },
        [KanbanState.VIEWED]: {
            ['right']: [{ newStatus: KanbanState.AWAITING_DETAILS, position: 'left', reportId: report.id }],
            ['left']: [{ newStatus: KanbanState.CONSIDERING, position: 'right', reportId: report.id }],
        },
        [KanbanState.AWAITING_DETAILS]: {
            ['right']: [{ newStatus: KanbanState.SHORTLISTED, position: 'left', reportId: report.id }],
            ['left']: [{ newStatus: KanbanState.VIEWED, position: 'right', reportId: report.id }],
        },
        [KanbanState.SHORTLISTED]: {
            ['right']: [{}],
            ['left']: [{ newStatus: KanbanState.AWAITING_DETAILS, position: 'right', reportId: report.id }],
        },
    }

    const getMovingTabInfo = (reportStatus, cardPosition) => {
        // return movingItemTabInfoObj[reportStatus]?.[cardPosition]?.[0]
        return movingItemTabInfoObj[reportStatus]
    }

    const [leftTabImage, setLeftTabImage] = useState('');
    const [rightTabImage, setRightTabImage] = useState('');
    const [tabForMovingReports, setTabForMovingReports] = useState(null)

    const onTabClick = (tabPosition) => {
        let tab = tabForMovingReports ? tabForMovingReports[tabPosition][0] : null;
        //if the position of image "left" its mean that we ove card to the "right"
        if (tab) {
            dispatch(moveCardOnKanban({ direction: tab.position === 'right' ? 'left' : 'right', reportId: report.id }))
        }
    }

    const choseImage = (imageObj) => {
        return imageObj?.newStatus === KanbanState.CONSIDERING ? considering
            : imageObj?.newStatus === KanbanState.AWAITING_DETAILS ? awaiting_details
                : imageObj?.newStatus === KanbanState.SHORTLISTED ? shortlisted
                    : imageObj?.newStatus === KanbanState.VIEWED ? viewed : null
    }

    const deleteReport = (reportId) => {
        dispatch(removeHome({ reportId, fromGlobalState: true }));
    }

    useEffect(() => {
        if (tabForMovingReports) {
            const rightImage = choseImage(tabForMovingReports['right'][0]);
            const leftImage = choseImage(tabForMovingReports['left'][0]);
            setLeftTabImage(leftImage);
            setRightTabImage(rightImage);
        }
    }, [tabForMovingReports])

    useEffect(() => {
        const newTabItem = getMovingTabInfo(report.state, direction);
        const newTab = newTabItem && newTabItem[direction] ? newTabItem[direction][0] : null;

        if (shouldBeMoved) {
            dispatch(moveCardOnKanban({ direction: shouldBeMoved, reportId: report.id }))
            setTabForMovingReports(null)
        } else {
            setTabForMovingReports(newTabItem)
        }
    }, [report, direction, shouldBeMoved])

    return (
        <div className={styles.swipe_block}>
            <animated.div ref={ref}
                data-name={report.state}
                {...bind()}
                onDragStart={(e) => e.preventDefault()}
                style={{ ...springProps, touchAction: 'pan-y' }}>
                <div className={styles.test_right}
                    onClick={() => onTabClick('left')}
                    style={{ display: !leftTabImage ? 'none' : '' }}>
                    <img src={leftTabImage} alt="" />
                </div>
                <KanbanCard index={index}
                    report={report}
                    preventClick={handleClick}
                    removeReport={deleteReport} />
                <div className={styles.test_left}
                    onClick={() => onTabClick('right')}
                    style={{ display: !rightTabImage ? 'none' : '' }}>
                    <img src={rightTabImage} alt="" />
                </div>
            </animated.div>
        </div>

    )
}

DraggablePropertyCard.propTypes = {
    index: PropTypes.number,
    report: PropTypes.object,
};

export default DraggablePropertyCard;
