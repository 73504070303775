import PropTypes from 'prop-types';
import { useEffect, useState } from "react";

import styles from './Noise.module.scss';
import { Row, Button, Img, Col, Modal } from '../Layout/index';

import { UserAPI } from '@/api/user';
import home from '@/assets/survey/sizedHome.svg';

export const HomeSize = ({ isOpen, onClose = () => { }, onNext = () => { }, preference }) => {
    useEffect(() => {
        if (preference[questions.pref]) {
            setQuestions({ ...questions, response: preference[questions.pref] })
        }
    }, [preference])

    const [questions, setQuestions] = useState({
        title: "Home Size",
        pref: "Home Size",
        question: "In your ideal home, how important is it to have as much space as possible?",
        choices: [
            {
                value: '2',
                name: <div><b style={{ fontWeight: '500' }}>Not important:</b> I can live in a box</div>,
            },
            {
                value: '4',
                name: <div><b style={{ fontWeight: '500' }}>Slightly important:</b> I can hold a family size party</div>,
            },
            {
                value: '6',
                name: <div><b style={{ fontWeight: '500' }}>Important:</b> I need a garage and lots of storage space</div>,
            },
            {
                value: '8',
                name: <div><b style={{ fontWeight: '500' }}>Very important:</b> I&apos;m in a castle!</div>,
            },
        ],
        response: '',
    });

    const handleResponse = (e) => {
        e.preventDefault();
        setQuestions({ ...questions, response: e.target.value });
    }

    const goNext = () => {
        if (questions.response !== '') {
            const body = {
                "score": parseInt(questions.response),
                "preference": "Home Size"
            }
            UserAPI.setUserPreferences(body);
            onNext();
        }
    }

    const superscript = <>
        <p style={{ display: 'inline', fontWeight: '600', fontSize: '1.5rem', fontFamily: 'Poppins' }}>5</p>
        <p style={{ display: 'inline', fontFamily: 'Poppins' }}>/8</p>
    </>

    return (
        <Modal isOpen={isOpen} onClose={onClose} title={questions.title} text={superscript}>
            <Col className={styles.contents_wrapper}>
                <p className={styles.description}>{questions.question}</p>
                <Row className={styles.main_content}>
                    <Img className={styles.image} src={home} />
                    <Col className={styles.questions_wrapper}>
                        {questions.choices.map((choice) => (
                            <Row key={choice.value} className={styles.mcq}>
                                <Button
                                    className={questions.response === choice.value ? styles.choose : styles.chose}
                                    style={{ backgroundColor: 'var(--survey-home)' }}
                                    value={choice.value}
                                    onClick={handleResponse}
                                />
                                <p>{choice.name}</p>
                            </Row>
                        ))}
                    </Col>
                </Row>
                <Button className={styles.continueBtn} onClick={goNext}>Save & Continue</Button>
            </Col>
        </Modal>
    )
}

HomeSize.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    preference: PropTypes.any,
}