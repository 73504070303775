import { Link, useNavigate } from "react-router-dom";

import styles from './index.module.scss';

import { UserAPI } from "@/api/user";
import { Authentication } from "@/components/Authentication";
import { Row } from "@/components/Layout";

export const ForgotPassword = () => {
    const subtitle = <>Password Reset
        <p style={{ fontSize: '16px' }}>
            Enter your email address below and we will send you an email to reset it.
        </p>
    </>;

    const navigate = useNavigate();

    const handleSubmit = async (name, email, password) => {
        await UserAPI.setCSRF();
        await UserAPI.emailNewPassword(email);
        navigate('/reset/confirmed');
    }

    return (
        <Authentication subtitle={subtitle} seeEmail={true} seePassword={false} buttonType={"Reset Password"} onClick={handleSubmit}>
            <Row className={`${styles.links_wrapper} flex-nowrap`}>
                <Link to={'/login'} className={'text-center text-decoration-underline'}>
                    Have an account?
                </Link>
                <Link to={'/register'} className={'text-center text-decoration-underline'}>
                    Don&apos;t have an account?
                </Link>
            </Row>
        </Authentication>
    )
}