import { track } from '@vercel/analytics/react';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { Button } from '../Layout/index';

import { Intro } from '@/components/v2Survey/Intro';
import { Quiz } from '@/components/v2Survey/Quiz';
import { ColorAccent } from '@/constants';

export const Survey = ({ title, className = "", style = {}, starting = false }) => {
    const [start, setStart] = useState(starting);
    const [quiz, setQuiz] = useState(starting);

    const beginQuiz = () => {
        track("Starting V2 Intake Quiz");
        setStart(true);
    }

    return (
        <div>
            <Intro isOpen={start} onClose={() => { setStart(false) }} onNext={() => { setQuiz(true) }} />
            <Quiz isOpen={quiz} onClose={() => { setQuiz(false) }} />
            <Button colorAccent={ColorAccent.white} onClick={() => { beginQuiz() }} className={`${className}`} style={style} aria-label="quiz">
                {title}
            </Button>
        </div>
    )
}


Survey.propTypes = {
    title: PropTypes.any.isRequired,
    className: PropTypes.string,
    style: PropTypes.any,
    starting: PropTypes.bool,
}