import PropTypes from 'prop-types';
import React from "react";

import styles from './bigDisplayBlock.module.scss';

import send from "@/assets/icons/sendMessage.svg";
import {ChatComponent} from "@/components/ChatComponent/chatComponent";
import {Col, Row, Button, Img, Input} from "@/components/Layout";

const BigDisplayBlock = ({
                             children, className = '', action = () => {
    }, chat, ...props
                         }) => {

    return (
        <Col className={`position-relative w-100 align-self-stretch ${className}`}>
            {children}
        </Col>
    )
}

BigDisplayBlock.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    action: PropTypes.func,
    chat: PropTypes.bool,
};

const Header = ({children, icon, className = '', chat, ...props}) => {
    return (
        <div className={`${className}`} style={{height: chat ? "70%" : "50%"}}>
            <div style={chat ? {display: 'none'} : {padding: '15px'}}>
                <div className={'w-100 d-flex justify-content-between align-items-center'}>
                    <div className={'w-100 d-flex align-items-center gap-2'}>
                        {children}
                    </div>
                    {icon && <Img src={icon} style={{height: '3.75rem'}}/>}
                </div>
            </div>
        </div>
    );
};

Header.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    icon: PropTypes.string,
    chat: PropTypes.bool,
};

BigDisplayBlock.Header = Header;

const Body = ({
                  children, className = '', priceBtnTitle, priceBtnStyles, chat, setChat, message
                  , handleMessage, handleKeyDown, closeCard, isMobileMini, chatContainerRef,
                  chatMessages, isWaitingForBotResponse, loading, handleMessageSend, withoutBodyAnimation, roomsChat, ...props
              }) => {
    return (
        <Row className={'align-items-stretch flex-grow-1 w-100'}>
            <div className={className} style={{height: chat ? "70%" : "50%"}}></div>
            <Col className={`${styles.chatBox} w-100 position-relative bg-white`} style={{marginTop: chat ? "60px" : "80px"}}>
                {
                    chat ? <ChatComponent chat={chat}
                                          setChat={setChat}
                                          chatContainerRef={chatContainerRef}
                                          chatMessages={chatMessages}
                                          isWaitingForBotResponse={isWaitingForBotResponse}
                                          loading={loading}
                                          message={message}
                                          handleMessage={handleMessage}
                                          handleMessageSend={handleMessageSend}
                                          handleKeyDown={handleKeyDown}/>
                        :
                        <Col className={`${withoutBodyAnimation ? styles.add_flex_class : styles.chatBoxAnimation2}`}>
                            <Col className={`${withoutBodyAnimation ? styles.add_flex_class : styles.chatBoxAnimation}`}>

                                <div className={priceBtnStyles}
                                     style={{display: withoutBodyAnimation ? 'none' : ''}}>
                                    {priceBtnTitle}
                                </div>

                                <Col className={`${!withoutBodyAnimation && styles.chatBoxWithBg} position-relative w-100 flex-grow-1 p-2`}>

                                    <Col className={`${styles.chatOptions} w-100 justify-content-start flex-grow-1 p-4 flex-nowrap`}>
                                        {children}
                                    </Col>

                                    <Row className={`${styles.chat2} w-100 flex-nowrap p-2 border border-2 border-dark position-relative mb-2`}>
                                        <Input
                                            placeholder='Type your message here...'
                                            value={message}
                                            onClick={() => setChat(!chat)}
                                            onChange={handleMessage}
                                            onKeyDown={handleKeyDown}
                                            className={'w-100 mx-4'} style={{fontSize: '1.125rem'}}/>
                                        <Img src={send} alt="Send" className={'cursor-pointer'} onClick={() => setChat(!chat)}/>
                                    </Row>

                                    <div className={`w-100 ${chat ? styles.unseenTag : styles.seenTag}`}
                                         style={{display: withoutBodyAnimation ? 'none' : ''}}>
                                        <Button className={`${styles.tagBtn} border border-2 border-dark bg-white`}
                                                onClick={closeCard}>
                                            {isMobileMini ? 'Close' : 'Close card'}
                                        </Button>
                                    </div>
                                </Col>
                            </Col>
                        </Col>
                }
            </Col>
        </Row>
    );
};

Body.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    priceBtnTitle: PropTypes.string,
    priceBtnStyles: PropTypes.string,
    chat: PropTypes.bool,
    setChat: PropTypes.func,
    message: PropTypes.string,
    handleMessage: PropTypes.func,
    handleKeyDown: PropTypes.func,
    closeCard: PropTypes.func,
    isMobileMini: PropTypes.bool,
    chatContainerRef: PropTypes.shape({current: PropTypes.instanceOf(Element)}),
    chatMessages: PropTypes.array,
    isWaitingForBotResponse: PropTypes.bool,
    loading: PropTypes.string,
    handleMessageSend: PropTypes.func,
    withoutBodyAnimation: PropTypes.bool,
    roomsChat: PropTypes.bool
};
BigDisplayBlock.Body = Body;

export default BigDisplayBlock;