import { CoatHanger, HouseLine, Lightbulb, PaintBrushHousehold, Resize, Wrench } from "@phosphor-icons/react";
import React from "react";

import appliances from "@/assets/rooms/appliances.png";
import archetecture from "@/assets/rooms/archetecture.png";
import backyard from "@/assets/rooms/backyard.png";
import basement from "@/assets/rooms/basement.png";
import bath from "@/assets/rooms/bath.png";
import closet_space from "@/assets/rooms/closet_space.png";
import countertops from '@/assets/rooms/countertops.png';
import flooring from "@/assets/rooms/flooring.png";
import lamp from "@/assets/rooms/lamp.png";
import lighting from "@/assets/rooms/lighting.png";
import patio from "@/assets/rooms/patio.png";
import pool from "@/assets/rooms/pool.png";
import roofing from "@/assets/rooms/roof.png";
import siding from "@/assets/rooms/siding.png";
import size from "@/assets/rooms/size.png";
import vanity from "@/assets/rooms/vanity.png";

export const statuses = {
    idle: 0,
    loading: 1,
};

export const rooms_values_colors = ['#5c9cdc', '#b2e78d', '#dcc68d', '#c295be',
    '#ce9a97', '#9cdca3', '#9b99ec', '#81efca', '#5c9cdc', '#5c9cdc',
    '#afd1e5', '#7276a1', '#d38ae3', '#e4ef81', '#5c9cdc', '#5c9cdc']


export const rooms_features = {
    'Size': { icon: <Resize size={24} />, image: size, bg: '#F5EAD9' },
    'Flooring': { icon: <HouseLine size={24} />, image: flooring, bg: '#916D5A' },
    'Quality': { icon: <Wrench size={24} />, image: null, bg: '#5F5F5F' },
    'Lighting': { icon: <Lightbulb size={24} />, image: lighting, bg: '#FFDE92' },
    'Accessibility': { icon: <HouseLine size={24} />, image: null, bg: '#5F5F5F' },
    'Vanity Styles': { icon: <HouseLine size={24} />, image: vanity, bg: '#ECC3AC' },
    'Bath': { icon: <HouseLine size={24} />, image: bath, bg: '#8D8D8D' },
    'Closets': { icon: <CoatHanger size={24} />, image: closet_space, bg: '#C9BCB3' },
    'Lighting Fixture': { icon: <Lightbulb size={24} />, image: lamp, bg: '#FAE9D9' },
    'Countertop Materials': { icon: <HouseLine size={24} />, image: countertops, bg: '#5F5F5F' },
    'Appliances': { icon: <HouseLine size={24} />, image: appliances, bg: '#4A6777' },
    'Basement': { icon: <HouseLine size={24} />, image: basement, bg: '#5F5F5F' },
    'Siding': { icon: <Resize size={24} />, image: siding, bg: '#C5C5C5' },
    'Paint Quality': { icon: <PaintBrushHousehold size={24} />, image: null, bg: '#AB430A' },
    'Roofing': { icon: <Resize size={24} />, image: roofing, bg: '#848484' },
    'Patio Presence': { icon: <Resize size={24} />, image: patio, bg: '#B5866E' },
    'Lot Size Openness': { icon: <Resize size={24} />, image: backyard, bg: '#83C373' },
    'Architectural Style': { icon: <HouseLine size={24} />, image: archetecture, bg: '#AB430A' },
    'Amenities': { icon: <HouseLine size={24} />, image: null, bg: '#8D8D8D' },
    'Property Exterior Quality': { icon: <HouseLine size={24} />, image: null, bg: '#83C373' },
    'Swimming Pool': { icon: <Resize size={24} />, image: pool, bg: '#B5866E' },
}