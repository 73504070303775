import {useEffect} from "react";
import { Link } from "react-router-dom";
import {toast} from "react-toastify";

import styles from './confirmChangePssword.module.scss';

import { Authentication } from "@/components/Authentication";
import {Row} from "@/components/Layout";



export const ConfirmChangePassword = () => {

    useEffect(() => {
        toast.success("Password successfully changed");
    }, [])

    return (
        <Authentication subtitle={"Change Password"} seeEmail={false} seePassword={false}>
            <p className={styles.content}>
                Your password has been changed. You can now <Link style={{ display: 'inline' }} className={'text-center text-decoration-underline'} to="/login">login</Link> with your new password.
            </p>
            <Row className={`${styles.links_wrapper} flex-nowrap`}>
                <Link to={'/login'} className={'text-center text-decoration-underline'}>
                    Have an account?
                </Link>
                <Link to={'/register'} className={'text-center text-decoration-underline'}>
                    Don&apos;t have an account?
                </Link>
            </Row>
        </Authentication>
    )
}