import PropTypes from "prop-types";
import React from "react";

import styles from './RoomsMenu.module.scss';


const RoomsMenu = ({menuItems, activeMenuItem, setActiveMenuItem }) => {

    return(
        <div className={styles.menu}>
            {menuItems?.map(item => {
                return (
                    <div onClick={() => setActiveMenuItem(item)}
                         className={`${styles.menu_item} ${item.title === activeMenuItem.title ? `${styles.current}` : ''}`}
                         key={item.id}>
                        <div className={styles.menu_item_avatar}>
                            <img src={item.image} alt=""/>
                        </div>
                        <div className={styles.menu_item_info}>
                            <div className={styles.title}>{item.title}</div>
                            <div className={styles.description}>{item.description}</div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

RoomsMenu.propTypes = {
    menuItems: PropTypes.array,
    activeMenuItem: PropTypes.object,
    setActiveMenuItem: PropTypes.func,
    // propertyId: PropTypes.string,
    // report: PropTypes.object,
};

export default RoomsMenu;