import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";

import commonStyles from './../../Issues.module.scss';
import styles from './issuesContainer.module.scss';


import home from "@/assets/survey/oasisHouse1.svg";
import Card, { CardLinkPosition, CardType } from "@/components/Card";
import { Img, Row } from "@/components/Layout";
import { useDesktop, useDesktopMini, useMobileMini } from "@/hooks/useAdaptives";
import { checkPosition } from "@/utils";
import { issue_category } from "@/views/PropertyInsights/Issues";


export const IssuesContainer = ({ report, displayIssue, handleCard, priceColor }) => {

    const isMobileMini = useMobileMini();
    const isDesktop = useDesktop();
    const isDesktopMini = useDesktopMini();

    const [hover, setHover] = useState({
        id: -1,
        isHovered: false
    });

    //for appearing arrow link
    const [activeCardIndex, setActiveCardIndex] = useState(null);
    const containerRef = useRef(null);

    // function from utils
    const checkElementPositions = useCallback(() => checkPosition(containerRef, activeCardIndex, setActiveCardIndex), [activeCardIndex]);

    useEffect(() => {
        if (isMobileMini) {
            window.addEventListener("scroll", checkElementPositions);

            return () => {
                window.removeEventListener("scroll", checkElementPositions);
            };
        }
    }, [displayIssue, isMobileMini]);

    return (
        <div ref={containerRef}
            style={{ maxHeight: !isDesktop && !isDesktopMini ? 'auto' : '715px' }}
            className={`${styles.smallCardsContainer} d-flex flex-wrap w-100 overflow-auto justify-content-center justify-content-lg-start gap-3`}>
            {report.has_report ?
                displayIssue.filter(issue => !issue.status)
                    .map((issue, index) => (
                        <Card key={index}
                            className={'text-start observed-element'}
                            type={CardType.smallBox}
                            hoveredClass={isMobileMini && activeCardIndex === index}
                            linkPosition={CardLinkPosition.topRight}
                            action={() => {
                                handleCard(issue)
                            }}
                            onMouseEnter={() => {
                                setHover({
                                    id: index,
                                    isHovered: true
                                })
                            }}
                            onMouseLeave={() => {
                                setHover({
                                    id: -1,
                                    isHovered: false
                                })
                            }}>
                            <Card.Header className={`${styles.card_header} py-1 gap-2`}>
                                <div className={styles.icon_wrapper}>
                                    {issue_category[issue.category]?.icon}
                                </div>
                                <p>
                                    {((hover.isHovered && index === hover.id) || (activeCardIndex === index)) && issue.issue.length >= 13 ? issue.issue.slice(0, 12) + '...' : issue.issue}
                                </p>
                            </Card.Header>

                            <Card.Body className={'pt-2 h-100'}>
                                <div className={styles.price_container}>
                                    <div className={`${commonStyles.price} ${priceColor}`}>
                                        ${issue.cost_estimate_low}-{issue.cost_estimate_high}
                                    </div>
                                </div>
                                <div className={`${styles.card_body_description} mt-2 overflow-auto`}>
                                    {issue.context}
                                </div>
                                <div className={`${styles.page_container} justify-content-between align-items-end`}>
                                    {/*<div className={commonStyles.price + ' ' + priceColor}>{issue.source}</div>*/}
                                    <div>{issue.source}</div>
                                    <Img src={issue_category[issue.category]?.image} />
                                </div>
                            </Card.Body>
                        </Card>
                    ))
                : <h1>No issues found. Please upload a report</h1>
            }
        </div>
    )
}

IssuesContainer.propTypes = {
    report: PropTypes.object,
    displayIssue: PropTypes.array,
    handleCard: PropTypes.func,
    priceColor: PropTypes.string,
};