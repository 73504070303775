import PropTypes from "prop-types";
import React, {useState} from "react";

import styles from './homeBuying.module.scss';

import {Button} from "@/components/Layout";
import {ColorAccent} from "@/constants";
import {useWindowHeight} from "@/hooks/useWindowHeight";
import common_styles from "@/views/Onboarding/onboarding.module.scss";


const HomeBuying = ({
                        onClose = () => {
                        },
                        onNext = () => {
                        }
                    }) => {
    const windowHeight = useWindowHeight();

    return(
        <div className={styles.main_wrapper}>
            <div className={common_styles.content_wrapper}>
                <div className={`${common_styles.info_card} ${windowHeight < 730 ? common_styles.small : ''}`}>
                    <div className={common_styles.title}>Home buying is <br/> a team sport </div>
                    <div className={common_styles.description}>Add your spouse, mom or realtor. Unfortunately you can’t add your dog yet...</div>
                    <Button colorAccent={ColorAccent.black} className={'w-100 py-3 mb-3'} onClick={onNext}>
                        Next
                    </Button>
                    <div className={common_styles.skip} onClick={onClose}>Skip for now</div>
                </div>
            </div>
        </div>
    )
}

HomeBuying.propTypes = {
    onClose: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
}
export default HomeBuying;