import { Link } from "react-router-dom";

import styles from './index.module.scss';

import { Authentication } from "@/components/Authentication";
import {Row} from "@/components/Layout";

export const EmailPassword = () => {
    return (
        <Authentication subtitle={"Password Reset"} seeEmail={false} seePassword={false}>
            <p className={styles.content}>
                We&apos;ve sent you an e-mail. If you do not receive it within a few minutes, please either try again <Link style={{ display: 'inline' }} className={'text-center text-decoration-underline'} to="/reset">here</Link> or contact us for additional help at contact@homescore.co.
            </p>
            <Row className={`${styles.links_wrapper} flex-nowrap`}>
                <Link to={'/login'} className={'text-center text-decoration-underline'}>
                    Have an account?
                </Link>
                <Link to={'/register'} className={'text-center text-decoration-underline'}>
                    Don&apos;t have an account?
                </Link>
            </Row>
        </Authentication>
    )
}