// implement basic axio config for coockies auth and csrf token
import { track } from '@vercel/analytics/react';
import axios from 'axios';
import { toast } from 'react-toastify';

import {navigateTo} from "@/navigateService";
import store, { dispatch } from '@/store';
import {logout, setLoading} from '@/store/user/slice';



export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        // 'Content-Type': 'application/json',
    },
});



api.interceptors.response.use(
    response => response,
    error => {
       const state = store.getState();
       const {loading} = state.user;
        if (!error.response) {
            track("Error", { status: 500, message: "Unexpected error while communicating with the server. Please try again later." })
            toast.error('Unexpected error while communicating with the server. Please try again later.');
        } else if (error.response.status === 401 || error.response.status === 403) {
            track("Error", { status: error.response.status, message: error.response.data?.detail || error.message })
            dispatch(logout());
        } else {
            // console.log('API error:', error.response);
            if(error.response.data?.username) {
                navigateTo('/login')
            }
            toast.error(error.response.data?.detail
                || (error.response.data?.username && error.response.data?.username.join(' '))
                || (error.response.data?.password1 && error.response.data?.password1.join(' '))
                || (error.response.data?.['new_password2'] && error.response.data?.['new_password2'].join(' '))
                || (error.response.data?.['non_field_errors'] && error.response.data?.['non_field_errors'].join(' '))
                || error.message
                || 'Error while communicating with the server.');
        }
        loading && store.dispatch(setLoading(false));
        return Promise.reject(error);
    },
);
