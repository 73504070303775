import PropTypes from 'prop-types';
import React from "react";
import { useSelector } from "react-redux"

import styles from './MainScreen.module.scss';

import { KanbanState } from '@/api/report';
import KanbanCard from "@/components/kanbanCard/kanbanCard";
import { dispatch } from "@/store";
import { removeHome } from "@/store/reports/reportsSlice";
import DraggableItem from "@/views/KanbanScreen/MainScreen/draggableItem/DraggableItem";
import DropZone from "@/views/KanbanScreen/MainScreen/draggableItem/DropZone/DropZone";

const MainScreen = ({ }) => {

    const { reports } = useSelector(state => state.reports);

    const deleteReport = (reportId) => {
        dispatch(removeHome({ reportId, fromGlobalState: true }));
    }

    const columns = [
        { id: 1, status: KanbanState.CONSIDERING, state: KanbanState.CONSIDERING },
        { id: 2, status: KanbanState.VIEWED, state: KanbanState.VIEWED },
        { id: 3, status: KanbanState.AWAITING_DETAILS, state: KanbanState.AWAITING_DETAILS },
        { id: 4, status: KanbanState.SHORTLISTED, state: KanbanState.SHORTLISTED },
    ]


    return (
        <div className={`${styles.properties_block}`}>
            {
                columns.map(i => {
                    return (
                        <div className={styles.property_type_wrapper} key={i.id}>
                            <div className={styles.title}>{i.status}</div>
                            <DropZone columnStatus={i.state}>
                                {reports && reports?.filter(rep => rep?.state === i?.state).map((item, index) => {
                                    return (
                                        <DraggableItem report={item} key={item.id}>
                                            <KanbanCard index={index} report={item} removeReport={deleteReport} style={{ userSelect: "none" }} />
                                        </DraggableItem>
                                    )
                                })}
                            </DropZone>
                        </div>
                    )
                })
            }

        </div>

    )
}

MainScreen.propTypes = {}

export default MainScreen;
