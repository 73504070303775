import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from "react";

import styles from './MobileScreen.module.scss';

import { KanbanState } from '@/api/report';
import { dispatch } from "@/store";
import DraggablePropertyCard from "@/views/KanbanScreen/MobileScreen/DraggablePropertyCard/DraggablePropertyCard";

const MobileScreen = ({ reports, activeTab, setActiveTab }) => {
    const blockRef = useRef(null);
    const [isAtBottom, setIsAtBottom] = useState(false);

    const handleScroll = () => {
        const block = blockRef?.current;
        if (!block) return;

        const scrollTop = block.scrollTop;
        const clientHeight = block.clientHeight;
        const scrollHeight = block.scrollHeight;

        if (scrollTop + clientHeight >= scrollHeight - 10) {
            if (!isAtBottom) {
                setIsAtBottom(true);
            }
        } else {
            setIsAtBottom(false);
        }
    }

    useEffect(() => {
        const block = blockRef?.current;
        if (block) {
            block.addEventListener('scroll', handleScroll);

            return () => {
                block.removeEventListener('scroll', handleScroll);
            };
        }
    }, [isAtBottom, activeTab, reports]);

    useEffect(() => {
        if (isAtBottom && activeTab.title === KanbanState.CONSIDERING && reports.length) {
            //  let reportsToMove = reports.map(r => ({reportId: r.id, newStatus: 'Viewed'}))
            // dispatch(updateReportStatus(reportsToMove));
            //  setActiveTab({id: 2, title: 'Viewed', state: 'review'});
            //  window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }, [isAtBottom])

    return (
        <div className={`d-flex flex-column justify-content-start align-items-center mb-2 ${styles.properties_block}`} ref={blockRef}>
            {reports.map((report, index) => (
                <div key={report.id} className={styles.property_wrapper}>
                    <DraggablePropertyCard key={index} index={index} report={report} />
                </div>
            ))}
        </div>

    )
}

MobileScreen.propTypes = {
    reports: PropTypes.array.isRequired,
    activeTab: PropTypes.object,
    setActiveTab: PropTypes.func,
}

export default MobileScreen;