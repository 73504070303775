import {Wrench} from "@phosphor-icons/react";
import PropTypes from "prop-types";
import React from "react";
import {Link} from "react-router-dom";

import commonStyles from './../../Issues.module.scss';
import styles from './recommendationsBlock.module.scss';

import phoneIcon from "@/assets/icons/phoneIcon.svg";
import websiteIcon from "@/assets/icons/websiteIcon.svg";
import {Row, Icon, Col} from "@/components/Layout";

export const RecommendationsBlock  = ({isLoading, cardPlaces}) => {

    return(
        <Row className={`${styles.recommendations} w-100 align-items-start mb-3 overflow-auto`}>
            {isLoading ? (
                <span className={commonStyles.customLoader}/>
            ) : (
                cardPlaces.map((item, index) => (
                    <Col
                        className={`${styles.cardPlace} gap-2 mb-2`}
                        key={index}>
                        <Wrench size={'1.5rem'}/>
                        <h4 className={styles.cardPlaceTitle}>{item.name}</h4>
                        <div
                            className={styles.cardPlaceDetails}>
                            {item.details.international_phone_number && (
                                <Link
                                    to={`tel:${item.details.international_phone_number}`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <Icon src={phoneIcon} className={'me-2'}/>
                                    {item.details.international_phone_number}
                                </Link>
                            )}
                            {item.details.website && (
                                <Link
                                    to={item.details.website}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <Icon src={websiteIcon} className={'me-2'}/>
                                    Website
                                </Link>
                            )}
                            <small>{item.formatted_address}</small>
                        </div>
                    </Col>
                ))
            )}
        </Row>
    )
}

RecommendationsBlock.propTypes = {
    isLoading: PropTypes.bool,
    cardPlaces: PropTypes.array,
};